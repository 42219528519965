import React, { useState } from "react";
import { useDispatch, useSelector }   from "react-redux";

import '../css/common.css';
import { selectPage }                 from "../features/pageSlice";
import { selectNovaSecurity,
	 setWeth,
	 setWbtc,
	 setCall,
	 setPut }                     from "../features/novaSecuritySlice";
import { selectNovaContractSelector,
	 setSelectedStrikePriceIdx,
	 effectExpirationIdx,
	 thunkGetStrikePrices,
	 thunkEffectStrikePriceIdx }  from "../features/novaContractSelectorSlice";
import { noContractsToNoSecurities }  from "../features/novaContractMemoSlice";

const ether = require('../lib/ether');
const novaEther = require('../lib/novaEther');
const common = require('../lib/common');
const BN = require("bn.js");


const prices = [ { id: 0, price: "invalid price", idx: 0, openInterest: "unknown", onClick: null, checked: false },
		 { id: 1, price: "invalid price", idx: 0, openInterest: "unknown", onClick: null, checked: false },
		 { id: 2, price: "invalid price", idx: 0, openInterest: "unknown", onClick: null, checked: false },
		 { id: 3, price: "invalid price", idx: 0, openInterest: "unknown", onClick: null, checked: false },
		 { id: 4, price: "invalid price", idx: 0, openInterest: "unknown", onClick: null, checked: false },
		 { id: 5, price: "invalid price", idx: 0, openInterest: "unknown", onClick: null, checked: false }
	       ];

let contractSelectorChangeMarkerPrev = -1;
let securityChangeMarkerPrev         = -1;


function setIdxCheckedValue(idx, isChecked) {
    const elem = document.getElementById(prices[idx].id);
    if (!!elem) {
	elem.checked = isChecked;
	prices[idx].checked = isChecked;
    }
}


function StrikePriceSelector(props) {
    const page = useSelector(selectPage);
    const novaSecurity = useSelector(selectNovaSecurity);
    const novaContractSelector = useSelector(selectNovaContractSelector);
    const dispatch = useDispatch();

    function clickHandler() {
	const buttons = document.getElementsByName("selectStrikePrice");
	for(let i = 0; i < buttons.length; ++i) {
            if(buttons[i].checked == true)
		dispatch(thunkEffectStrikePriceIdx(prices[i].idx));
	}
    };

    if (page.pageMode == "select") {
	if (!!novaSecurity.security) {
	    if (contractSelectorChangeMarkerPrev != novaContractSelector.changeMarker   ||
		securityChangeMarkerPrev         != novaSecurity.changeMarker                ) {
		contractSelectorChangeMarkerPrev = novaContractSelector.changeMarker
		securityChangeMarkerPrev         = novaSecurity.changeMarker
		const securityId = novaSecurity.security.id;
		const expirationIdx = novaContractSelector.selectedExpirationIdx;
		dispatch(thunkGetStrikePrices({ securityId, expirationIdx }));
	    }
	    const j = novaContractSelector.selectedExpirationIdx - novaSecurity.startIntervalIdx;
	    let foundIdx = 0;
	    for (let i = 0; i < 6; ++i) {
		prices[i].id = "spSelector" + i;
		prices[i].checked = false;
		prices[i].openInterest = "unknown";
		if (!!novaSecurity.openInterestBNs) {
		    console.log("openInterestBNs[" + j + "] = " + novaSecurity.openInterestBNs[j]);
		    const openInterestByStrikePrices = novaSecurity.openInterestBNs[j];
		    const openInterestBN = openInterestByStrikePrices[i];
		    const openInterestMaybeDecimals = ether.convertWeiToDecimal(openInterestBN.toString(10), 3);
		    const openInterestDecimal3 = parseFloat(openInterestMaybeDecimals).toFixed(3);
		    const noSecurities = noContractsToNoSecurities(novaSecurity.security, openInterestBN)
		    prices[i].openInterest = openInterestDecimal3 + ' (' + noSecurities.decimal3 + ' ' + novaSecurity.securityName + ')';
		}
		const priceMaybeDecimals = ether.convertWeiToDecimal(novaContractSelector.strikePriceBNs[i].toString(10), 2);
		const priceWith2Decimals = parseFloat(priceMaybeDecimals).toFixed(2);
		prices[i].price = priceWith2Decimals + ' DAI';
		prices[i].idx = i;
		if (i == novaContractSelector.selectedStrikePriceIdx)
		    foundIdx = i;
		prices[i].onClick = clickHandler;
	    }
	    setIdxCheckedValue(foundIdx, true);
	    if (prices[foundIdx].idx != novaContractSelector.selectedStrikePriceIdx) {
		dispatch(thunkEffectStrikePriceIdx(prices[foundIdx].idx));
	    }
	} else {
	    for (let i = 0; i < 6; ++i) {
		prices[i] = { id: "spSelector" + i, price: "invalid price", idx: 0, onClick: null, checked: false };
		setIdxCheckedValue(i, false);
	    }
	}
    }

    return (
          <div class="strikePriceSelectorTable">
            <form action="">
	      <table class="strikePriceSelectorTable">
		<tr>
		  <th>strike-price</th>
		  <th>open interest</th>
		  <th>select</th>
		</tr>
		{prices.map(price => (
			   <tr>
			     <td>{price.price}</td>
			     <td className="selectorTableOpenInterest">{price.openInterest}</td>
			     <td>
			       <input key={price.id} type="radio" id={price.id} value={price.id} name="selectStrikePrice" onClick={price.onClick} />
			     </td>
			   </tr>
			   ))}
	      </table>
	    </form>
          </div>
	);
}

export default StrikePriceSelector;
