import React from 'react';
import '../css/common.css';

function ViewMyActivityButton(props) {
    return (
	    <div id="viewMyActivityButtonDiv">
	    <button className="leftSideButton" type="button">View all my activity</button>
	    </div>
	);
}

export default ViewMyActivityButton;
