import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import '../css/common.css';
import { setMode, selectPage }      from "../features/pageSlice";
import { selectNovaSecurity }       from "../features/novaSecuritySlice";

const common = require('../lib/common');


const TradeContractButton = () => {
    const page = useSelector(selectPage);
    const novaSecurity = useSelector(selectNovaSecurity);

    const beenRendered = !!document.getElementById('tradeContractButtonDiv');
    if (beenRendered) {
	if (page.pageMode == "trade")
	    common.replaceElemClassFromTo("tradeContractButtonDiv", "visible", "selected", false);
	else {
	    // technically we could enable the trade-contract functionality before the secirity info is displayed;
	    // but i think it's cleaner to require the user to wait
	    common.replaceElemClassFromTo("tradeContractButtonDiv", "selected", "visible", novaSecurity.securityInfoIsPending);
	}
    }

    const dispatch = useDispatch();
    const onSubmit = () => {
	dispatch(setMode('trade'))
    };

    return (
	    <button id="tradeContractButtonDiv" type="button" className="plank panel pageModeButton visible" onClick={onSubmit}>Trade selected contract</button>
    );
}

export default TradeContractButton;
