import React, { useState } from "react";
import { useDispatch, useSelector }   from "react-redux";

import '../css/common.css';
import { selectPage             }     from "../features/pageSlice";
import { selectNovaSecurity,
	 thunkGetSecurities,
	 setDaily, setWeekly, setMonthly,
	 setWeth, setWbtc,
	 setCall, setPut }            from "../features/novaSecuritySlice";
import   ExpirationSelector           from './ExpirationSelector'
import   StrikePriceSelector          from './StrikePriceSelector'
const common = require('../lib/common');


const SelectContractWidget = () => {
    const page = useSelector(selectPage);
    const novaSecurity = useSelector(selectNovaSecurity);

    console.log('page = ' + page + ', novaSecurity = ' + novaSecurity);
    const dispatch = useDispatch();
    if (!novaSecurity.gotSecurities && !novaSecurity.securityInfoIsPending)
	dispatch(thunkGetSecurities());

    const beenRendered = !!document.getElementById('selectContractWidgetDiv');
    if (beenRendered) {
	if (page.pageMode != "select") {
	    common.replaceElemClassFromTo("selectContractWidgetDiv", "visible", "hidden");
	} else {
	    if (!!novaSecurity.securityInfoIsPending)
		common.replaceElemClassFromTo("selectContractWaitIcon", "hidden", "visible");
	    else
		common.replaceElemClassFromTo("selectContractWaitIcon", "visible", "hidden");
	    if (novaSecurity.dailySelected) {
		common.setButtonState("dailyButton",   "Selected");
		common.setButtonState("weeklyButton",  "Enabled");
		common.setButtonState("monthlyButton", "Enabled");
	    } else if (novaSecurity.weeklySelected) {
		common.setButtonState("dailyButton",   "Enabled");
		common.setButtonState("weeklyButton",  "Selected");
		common.setButtonState("monthlyButton", "Enabled");
	    } else if (novaSecurity.monthlySelected) {
		common.setButtonState("dailyButton",   "Enabled");
		common.setButtonState("weeklyButton",  "Enabled");
		common.setButtonState("monthlyButton", "Selected");
	    }
	    if (novaSecurity.wethSelected) {
		common.setButtonState("wethButton", "Selected");
		common.setButtonState("wbtcButton", "Enabled");
	    } else {
		common.setButtonState("wethButton", "Enabled");
		common.setButtonState("wbtcButton", "Selected");
	    }
	    if (novaSecurity.callSelected) {
		common.setButtonState("callButton", "Selected");
		common.setButtonState("putButton",  "Enabled");
	    } else {
		common.setButtonState("callButton", "Enabled");
		common.setButtonState("putButton",  "Selected");
	    }
	    common.replaceElemClassFromTo("selectContractWidgetDiv", "hidden", "visible");
	}
    }

    const onDailySubmit   = () => dispatch(setDaily());
    const onWeeklySubmit  = () => dispatch(setWeekly());
    const onMonthlySubmit = () => dispatch(setMonthly());

    const onCallSubmit = () => dispatch(setCall());
    const onPutSubmit  = () => dispatch(setPut());
    const onWethSubmit = () => dispatch(setWeth());
    const onWbtcSubmit = () => dispatch(setWbtc());

    return (
	    <div id="selectContractWidgetDiv" class="plank panel visible">
            <div id="selectContractWaitIcon" className="hidden"/>
	    <div id="selectContractWidgetDivLeft">
	      <button id="dailyButton"   type="button" className="button" onClick={onDailySubmit}  >Daily</button>
	      <button id="weeklyButton"  type="button" className="button" onClick={onWeeklySubmit} >Weekly</button>
	      <button id="monthlyButton" type="button" className="button" onClick={onMonthlySubmit}>Monthly</button>
	    </div>
	    <div id="selectContractWidgetDivMiddle">
	      <button id="callButton" type="button" className="button" onClick={onCallSubmit}>Call</button>
	      <button id="putButton"  type="button" className="button" onClick={onPutSubmit} >Put</button>
	      <button id="wethButton" type="button" className="button" onClick={onWethSubmit}>WETH</button>
	      <button id="wbtcButton" type="button" className="button" onClick={onWbtcSubmit}>WBTC</button>
	      <ExpirationSelector/>
	      <StrikePriceSelector/>
	    </div>
	    </div>
	    );
}

export default SelectContractWidget;
