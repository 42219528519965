import React, { useState } from "react";
import { useDispatch, useSelector }   from "react-redux";

import '../css/common.css';
import { selectPage }                 from "../features/pageSlice";
import { selectWallet }               from "../features/walletSlice";
import { selectNovaSecurity }         from "../features/novaSecuritySlice";
import { selectNovaContractSelector } from "../features/novaContractSelectorSlice";
import { selectNovaContractMemo }     from "../features/novaContractMemoSlice";
import { selectNovaOrderBook,
	 thunkGetOrderBook }          from "../features/novaOrderBookSlice";
import { selectNovaPlaceOrder,
	 thunkCalcOrderDepositAmount,
	 setOrderIsBuy,
	 setOrderPrice,
	 setOrderAmount }             from "../features/novaPlaceOrderSlice";

const novaEther = require('../lib/novaEther');
const ether = require('../lib/ether');
const common = require('../lib/common');
const BN = require("bn.js");

let walletChangeMarkerPrev        = -1;
let contractMemoChangeMarkerPrev  = -1;
let orderBookChangeMarkerPrev     = -1;

// the number of results (orders) that we want to retrieve to display the orderBook.
// this will change if the user scrolls.
let bidResults = 10;
let askResults = 10;
let midPriceDisplay = '';
let spreadDisplay = '';
let percentDisplay = '';
let securityText = 'WETH';
let selectedDisplay = "Selected contract: No Option Contract Selected";
let asks = [];
let bids = [];

const OrderBook = () => {
    const page = useSelector(selectPage);
    const wallet = useSelector(selectWallet);
    const novaSecurity = useSelector(selectNovaSecurity);
    const novaContractSelector = useSelector(selectNovaContractSelector);
    const novaContractMemo = useSelector(selectNovaContractMemo);
    const novaOrderBook = useSelector(selectNovaOrderBook);
    const novaPlaceOrder = useSelector(selectNovaPlaceOrder);
    const dispatch = useDispatch();

    const selectedDsp = (security) => {
	const intervalBN = security.expirationIntervalBN;
	const dateBN = security.expirationBaseBN.clone();
	dateBN.iadd(intervalBN.muln(novaContractSelector.selectedExpirationIdx));
	const date = new Date();
	date.setTime(dateBN.muln(1000).toString(10));
	const dateDisplay = common.days[date.getUTCDay()] + ' ' + common.months[date.getUTCMonth()] + ' ' +
	      common.leftPadTo(date.getUTCDate().toString(), 2, '0')  + ' ' +
	      common.leftPadTo(date.getUTCHours().toString(), 2, '0') + ":" +
	      common.leftPadTo(date.getUTCMinutes().toString(), 2, '0')  + ":" +
	      common.leftPadTo(date.getUTCSeconds().toString(), 2, '0');
	securityText = novaSecurity.wethSelected ? "WETH"  : "WBTC";
	//Call 100 WETH @ 200.00 exp: 20201104:230000 UTC
	const priceMaybeDecimals = ether.convertWeiToDecimal(novaContractSelector.selectedStrikePriceBN.toString(10), 2);
	const priceWith2Decimals = parseFloat(priceMaybeDecimals).toFixed(2);
	const tokensPerContract = parseInt(ether.convertWeiToDecimal(security.tokensPerContractBN.toString(10), 3));
	const selectedDisplay =
	    (novaSecurity.callSelected ? "Call" : "Put") + " " +
	    tokensPerContract + " " +
	    securityText + " " +
	    "@ " + priceWith2Decimals + " " +
	    "exp: " + dateDisplay;
	return(selectedDisplay);
    };

    const BNToDsp = (xBN, decimals) => {
	const valueMaybeDecimals = ether.convertWeiToDecimal(xBN.toString(10), decimals);
	const valueWithDecimals = parseFloat(valueMaybeDecimals).toFixed(decimals);
	return(valueWithDecimals);
    }

    const onScroll = () => {
	console.log('onscroll: ');
	// scrollHeight is the entire height, including the part of the elem that is not viewable because it is scrolled.
	// scrollTop is a measurement of the distance from the element's top to its topmost visible content. clientHeight
	// is a measure of the viewable area. so if you are scrolled all the way to the top, then scrollTop == 0; if you
	// are scrolled all the way to the bottom, then scrollTop = scrollHeight - clientHeight.
	const orderBookSubPanelElem = document.getElementById("orderBookSubPanel");
	if (!!orderBookSubPanelElem) {
	    const bottomScrollLimit = orderBookSubPanelElem.scrollHeight - orderBookSubPanelElem.clientHeight;
	    //console.log('scrollHeight = ' + orderBookSubPanelElem.scrollHeight +
	    //            ', scrollTop = ' + orderBookSubPanelElem.scrollTop +
	    //            ', clientHeight = ' + orderBookSubPanelElem.clientHeight);
	    if (!novaOrderBook.orderBookIsPending) {
		// only scroll up if number of asks that we have is equal to the amount we asked for last time
		// only scroll down if number of bids that we have is equal to the amount we asked for last time
		if ((novaOrderBook.orderBookAsks.length >= askResults) &&
		    (orderBookSubPanelElem.scrollTop       < 30         ) ) {
		    askResults += 10;
		    dispatch(thunkGetOrderBook({ maxResults: askResults, isBid: false }));
		} else if ((novaOrderBook.orderBookBids.length >= bidResults            ) &&
			   (orderBookSubPanelElem.scrollTop       >  bottomScrollLimit - 30) ) {
		    bidResults += 10;
		    dispatch(thunkGetOrderBook({ maxResults: bidResults, isBid: true }));
		}
	    }
	}
    };

    const contractsToSecurities = (contractAmount) => {
	const security = novaSecurity.security;
	const tokensPerContract = ether.convertWeiToDecimal(security.tokensPerContract, 3);
	const securityAmount = !!contractAmount ? common.fixedFloat(common.fixedFloat(contractAmount, 3) * tokensPerContract, 3) : 0;
	return({ contractAmount: contractAmount, securityAmount: securityAmount });
    };

    const orderBookToPlaceOrder = (orderIsBuy, price, amount) => {
	const amounts = contractsToSecurities(amount);
	dispatch(setOrderIsBuy(orderIsBuy));
	dispatch(setOrderPrice(price));
	dispatch(setOrderAmount(amounts));
	dispatch(thunkCalcOrderDepositAmount({ }));
    };


    if (page.pageMode == 'trade' &&
	(contractMemoChangeMarkerPrev != novaContractMemo.changeMarker ||
	 walletChangeMarkerPrev       != wallet.changeMarker            )) {
	walletChangeMarkerPrev        = wallet.changeMarker;
	contractMemoChangeMarkerPrev  = novaContractMemo.changeMarker;
	askResults = bidResults = 10;
	dispatch(thunkGetOrderBook({ maxResults: askResults, isBid: true  }));
	dispatch(thunkGetOrderBook({ maxResults: bidResults, isBid: false }));
    }

    const beenRendered = !!document.getElementById('orderBookPanel');
    if (beenRendered) {
	if (page.pageMode != 'trade') {
	    common.replaceElemClassFromTo("orderBookPanel", "visible", "hidden")
	} else {
	    common.replaceElemClassFromTo("orderBookPanel", "hidden", "visible");
	    if (!!novaOrderBook.orderBookIsPending)
		common.replaceElemClassFromTo("orderBookWaitIcon", "hidden", "visible");
	    else
		common.replaceElemClassFromTo("orderBookWaitIcon", "visible", "hidden");
	    if (false && !novaContractMemo.contractMemo) {
		console.log('OrderBook: REDRAWING no contractMemo (ie. never written)');
	    } else if (orderBookChangeMarkerPrev != novaOrderBook.changeMarker) {
		console.log('OrderBook: REDRAWING');
		orderBookChangeMarkerPrev = novaOrderBook.changeMarker;
		const security = novaSecurity.security;
		selectedDisplay = selectedDsp(security);
		let bestBid = null;
		let bestAsk = null;
		if (!!novaOrderBook.orderBookAsks) {
		    // note that duplicate prices result in empty order entries
		    console.log('OrderBook: ' + novaOrderBook.orderBookAsks.length + ' asks');
		    asks = [];
		    for (let i = novaOrderBook.orderBookAsks.length - 1; i >= 0; --i) {
			const order = novaOrderBook.orderBookAsks[i];
			console.log('ask order = ' + JSON.stringify(order));
			if (!!order) {
			    bestAsk = order;
			    const yieldBN = order.priceBN.mul(order.amountBN).div(ether.etherBN);
			    const price = BNToDsp(order.priceBN, 2);
			    const amount = BNToDsp(order.amountBN, 3)
			    const onClick = () => { orderBookToPlaceOrder(true, price, amount); };
			    asks.push( { price: price,
					 amount: amount,
					 yield: BNToDsp(yieldBN, 3),
				         onClick: onClick });
			}
		    }
		    /*
		    const action = isBid ? "buy" : "sell";
		    const orderId = isBid ? userOrder.userBidBN.toString(10) : userOrder.userOfferBN.toString(10);
		    const filledSumBN = new BN("0");
		    const proceedsSumBN = new BN("0");
		    const offerDateDsp = dateDsp(userOrder.date);
		    const contractAmountDsp = BNToDsp(userOrder.totalBN, 3);
		    const filledBN = userOrder.totalBN.sub(userOrder.remainBN);
		    let remainBN = userOrder.remainBN;
		    let deleteAction = null;
		    let status = (userOrder.openEvents.length == 0) ? "closed" : "";
		    */
		}
		if (!!novaOrderBook.orderBookBids) {
		    console.log('OrderBook: ' + novaOrderBook.orderBookBids.length + ' bids');
		    bids = [];
		    for (let i = 0; i < novaOrderBook.orderBookBids.length; ++i) {
			const order = novaOrderBook.orderBookBids[i];
			console.log('bid order = ' + JSON.stringify(order));
			if (!!order) {
			    if (!bestBid)
				bestBid = order;
			    const yieldBN = order.priceBN.mul(order.amountBN).div(ether.etherBN);
			    const price = BNToDsp(order.priceBN, 2);
			    const amount = BNToDsp(order.amountBN, 3)
			    const onClick = () => { orderBookToPlaceOrder(false, price, amount); };
			    bids.push( { price: BNToDsp(order.priceBN, 2),
					 amount: BNToDsp(order.amountBN, 3),
					 yield: BNToDsp(yieldBN, 3),
				         onClick: onClick });
			}
		    }
		}
		if (!!novaOrderBook.orderBookBids && !!bestBid &&
		    !!novaOrderBook.orderBookAsks && !!bestAsk) {
		    const midPriceBN = bestAsk.priceBN.add(bestBid.priceBN).divn(2);
		    const spreadBN = bestAsk.priceBN.sub(bestBid.priceBN);
		    const percentBN = spreadBN.mul(ether.etherBN).div(midPriceBN).muln(100);
		    midPriceDisplay = BNToDsp(midPriceBN, 2);
                    spreadDisplay = BNToDsp(spreadBN, 2),
		    percentDisplay = BNToDsp(percentBN, 2) + '%';
		} else {
		    midPriceDisplay = '';
		    spreadDisplay = '';
		    percentDisplay = '';
		}
	    }
	}
    }

    const centerLineElem = document.getElementById("orderBookCenterLine");
    console.log("askResults = " + askResults + " bidResults = " + bidResults + " asks.length = " + asks.length + " bids.length = " + bids.length);
    if (!!centerLineElem && askResults == 10 && bidResults == 10 && asks.length + bids.length > 10) {
	centerLineElem.scrollIntoView({ block: "center" });
	const root = document.getElementById("root");
	if (!!root)
	    root.scrollIntoView(true)
    }

    return (
	    <div id="orderBookPanel" class="panel hidden">
              <div id="orderBookWaitIcon" className="hidden"/>
	      <div class="panelTitle">
		Order Book
	      </div>
	      <div class="panelSubtitle">
		{selectedDisplay}
              </div>
	      <table class="orderBookTable">
		<tr>
		  <th id="orderBookTablePriceHdr">price (DAI)</th>
		  <th id="orderBookTableAmountHdr">amount (contracts)</th>
		  <th id="orderBookTableYieldHdr">total (DAI)</th>
		</tr>
	    </table>
	      <div id="orderBookSubPanel" onScroll={onScroll}>
		<table class="orderBookTable">
		  {asks.map(order => (
			<tr>
			  <td className="orderBookTablePrice sellColor" onClick={order.onClick}>{order.price}</td>
			  <td className="orderBookTableAmount" onClick={order.onClick}>{order.amount}</td>
			  <td className="orderBookTableYield" onClick={order.onClick}>{order.yield}</td>
			</tr>
			))}
		  <tr id="orderBookCenterLine">
		    <td className="orderBookTablePrice orderBookCenterLine">{midPriceDisplay}</td>
		    <td className="orderBookTableAmount orderBookCenterLine">{spreadDisplay}</td>
		    <td className="orderBookTableYield orderBookCenterLine">{percentDisplay}</td>
		  </tr>
		  {bids.map(order => (
			<tr>
			  <td className="orderBookTablePrice buyColor" onClick={order.onClick}>{order.price}</td>
			  <td className="orderBookTableAmount" onClick={order.onClick}>{order.amount}</td>
			  <td className="orderBookTableYield" onClick={order.onClick}>{order.yield}</td>
			</tr>
			))}
		</table>
	      </div>
	    </div>
	  );
}

export default OrderBook;
