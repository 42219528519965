import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { thunkCheckBalances }            from "../features/walletSlice";
import { thunkGetContractMemo }          from "../features/novaContractMemoSlice";

// needed for async when using bable
const regeneratorRuntime = require("regenerator-runtime");
const common = require('../lib/common');
const ether = require('../lib/ether');
const novaEther = require('../lib/novaEther');
const to = require('../lib/to');
const BN = require("bn.js");

// --------------------------------------------------------------------------------------------------------------------------
// thunks
// --------------------------------------------------------------------------------------------------------------------------

//
// get a list of my options to state.optionEvents[]
//
export const thunkGetMyOptions = createAsyncThunk(
    'novaOptionEvents/thunkGetMyOptions',
    async (arg, thunkAPI) => {
        console.log('im in the thunkGetMyOptions thunk');
	const state = thunkAPI.getState();
	return new Promise(async (resolve, reject) => {
	    try {
		const contractMemoId = state.novaContractMemo.contractMemoId;
		console.log('contractMemoId = ' + contractMemoId);
		const optionEvents = [];
		const optionLogs = await ether.getLogs({ fromBlock: 'earliest', toBlock: 'latest', address: novaEther.OPTIONCONTRACT_ADDR,
							 topics: [ novaEther.getOptionContractEvTopic0(),
								   common.numberToHex256(ether.LOCAL_ETH_ADDR),
								   common.numberToHex256(contractMemoId),
								 ] });
		console.log('got logs: ' + JSON.stringify(optionLogs));
		for (let i = 0; i < optionLogs.length; ++i) {
		    const optionEvent = await novaEther.parseOptionContractEv(optionLogs[i]);
		    optionEvents.push(optionEvent);
		}
		thunkAPI.dispatch(setOptionEvents(optionEvents));
		resolve('thunkGetMyOptions: okey dokey');
	    } catch (err) {
		console.error('thunkGetMyOptions err = ' + err);
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
)


//
// cancel an option
//
/*
export const thunkCancelOption = createAsyncThunk(
    'novaOptionEvents/thunkCancelOption',
    async (arg, thunkAPI) => {
        console.log('im in the thunkCancelOption thunk');
	const state = thunkAPI.getState();
	return new Promise(async (resolve, reject) => {
	    try {
		const securityId       = state.novaSecurity.security.id;
		const expirationIdx    = state.novaContractSelector.selectedExpirationIdx;
		const strikePriceIdx   = state.novaContractSelector.selectedStrikePriceIdx;
		const contractMemoId   = await novaEther.getContractMemoId(securityId, expirationIdx, strikePriceIdx);
		const optionIdBN        = arg;
		console.log('thunkCancelOption: contractMemoId = ' + contractMemoId + ', id = ' + optionIdBN.toString(10));
		if (!!contractMemoId && !!parseInt(contractMemoId)) {
		    console.log('thunkCancelOption: 1');
		    const txid = await novaEther.cancelOption(contractMemoId, optionIdBN);
		    console.log('thunkCancelOption: 2');
		} else {
		    console.log('thunkCancelOption: 3');
		    throw('Contract must exist before canceling an option');
		}
		// process refunded deposits
		console.log('thunkCancelOption: 4');
		thunkAPI.dispatch(thunkCheckBalances());
		// reserved amount may have changed
		console.log('thunkCancelOption: 5');
		thunkAPI.dispatch(thunkGetContractMemo({ securityId:     securityId,
							 expirationIdx:  expirationIdx,
							 strikePriceIdx: strikePriceIdx }));
		console.log('thunkCancelOption: 6');
		resolve('thunkCancelOption: okey dokey');
	    } catch (err) {
		console.error('thunkCancelOption err = ' + err);
		// for some reason rejectWithValue is ineffective when a metamask transaction is rejected. hence rejectHack.
		thunkAPI.dispatch(rejectHack());
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
)
*/

function setPending(state, set) {
    console.log("setPending(state, " + set + ") cnt = " + state.optionEventsPendingCnt);
    if (set) {
	++state.optionEventsPendingCnt;
	state.optionEventsIsPending = true;
    } else {
	if (--state.optionEventsPendingCnt <= 0) {
	    state.optionEventsPendingCnt = 0
	    state.optionEventsIsPending = false;
	}
    }
}

export const novaOptionEventsSlice = createSlice({
    name: 'novaOptionEvents',
    initialState: {
	changeMarker: 0,
	optionEventsPendingCnt: 0,
	optionEventsIsPending: false,
	//
	doExpandOptionList: false,
	optionListType: 'all',
	optionEvents: [],
    },
    reducers: {
	setOptionEvents: (state, action) => {
	    state.optionEvents = action.payload;
	},
	setExpandOptionList: (state, action) => {
	    state.doExpandOptionList = action.payload;
	},
	setOptionListType: (state, action) => {
	    state.optionListType = action.payload;
	},
	rejectHack: (state, action) => {
	    // for some reason rejectWithValue is ineffective when a metamask transaction is rejected. hence rejectHack.
	    setPending(state, false);
	},
    },
    extraReducers: {
	// Add reducers for additional action types here, and handle loading state as needed
	// thunks will have X.pending, X.fulfilled, X.rejected
	[thunkGetMyOptions.pending]:   (state, action) => { setPending(state, true);  },
	[thunkGetMyOptions.fulfilled]: (state, action) => { setPending(state, false); },
	[thunkGetMyOptions.rejected]:  (state, action) => { setPending(state, false); },
	/*
	[thunkCancelOption.pending]:   (state, action) => { setPending(state, true);  },
	[thunkCancelOption.fulfilled]: (state, action) => { setPending(state, false); },
	[thunkCancelOption.rejected]:  (state, action) => { setPending(state, false); },
	*/
    }
})


export const {
    setContractMemo,
    setOptionEvents,
    setExpandOptionList,
    setOptionListType,
    rejectHack,
} = novaOptionEventsSlice.actions;


export const selectNovaOptionEvents = state => state.novaOptionEvents;
export default novaOptionEventsSlice.reducer;
