import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { thunkCheckBalances }            from "../features/walletSlice";
import { thunkGetContractMemo }          from "../features/novaContractMemoSlice";

// needed for async when using bable
const regeneratorRuntime = require("regenerator-runtime");
const common = require('../lib/common');
const ether = require('../lib/ether');
const novaEther = require('../lib/novaEther');
const to = require('../lib/to');
const BN = require("bn.js");

// --------------------------------------------------------------------------------------------------------------------------
// thunks
// --------------------------------------------------------------------------------------------------------------------------

//
// thunks
//
export const thunkCalcWriteDepositAmount = createAsyncThunk(
    'novaWriteContract/thunkCalcWriteDepositAmount',
    async (arg, thunkAPI) => {
        console.log('im in the thunkCalcWriteDepositAmount thunk');
	const state = thunkAPI.getState();
	return new Promise(async (resolve, reject) => {
	    try {
		const securityId       = state.novaSecurity.security.id;
		const strikePriceBN    = !!state.novaContractSelector.selectedStrikePriceBN ? state.novaContractSelector.selectedStrikePriceBN : new BN(0);
		const contractAmountBN = !!state.novaWriteContract.writeContractAmountWeiBN ? state.novaWriteContract.writeContractAmountWeiBN : new BN(0);
		const security = await novaEther.getSecurity(securityId);
		if (!security)
		    throw('invalid security');
		if (security.isPut) {
		    // put requires deposit of sufficent dai to complete purchase
		    const daiDepositAmountWeiBN = contractAmountBN.mul(security.tokensPerContractBN).mul(strikePriceBN).div(ether.etherBN).div(ether.etherBN);
		    const daiDepositAmount = ether.convertWeiToDecimal(daiDepositAmountWeiBN.toString(10), 2);
		    thunkAPI.dispatch(setWriteDepositAmount({ depositAmount: daiDepositAmount, depositAmountWeiBN: daiDepositAmountWeiBN }));
		} else {
		    // call requires deposit of sufficent security to complete sale
		    const securityAddr = common.numberToBN(security.tokenAddr);
		    const wethAddr = common.numberToBN(novaEther.WETH_ADDR);
		    const wbtcAddr = common.numberToBN(novaEther.WBTC_ADDR);
		    if (securityAddr.eq(wethAddr)) {
			const wethDepositAmountWeiBN = contractAmountBN.mul(security.tokensPerContractBN).div(ether.etherBN);
			const wethDepositAmount = ether.convertWeiToDecimal(wethDepositAmountWeiBN.toString(10), 3);
			thunkAPI.dispatch(setWriteDepositAmount({ depositAmount: wethDepositAmount, depositAmountWeiBN: wethDepositAmountWeiBN }));
		    } else if (securityAddr.eq(wbtcAddr)) {
			const wbtcDepositAmountWeiBN = contractAmountBN.mul(security.tokensPerContractBN).div(ether.etherBN);
			const wbtcDepositAmount = ether.convertWeiToDecimal(wbtcDepositAmountWeiBN.toString(10), 3);
			thunkAPI.dispatch(setWriteDepositAmount({ depositAmount: wbtcDepositAmount, depositAmountWeiBN: wbtcDepositAmountWeiBN }));
		    } else {
			throw('unknown token address');
		    }
		}
		resolve('thunkCalcWriteDepositAmount: okey dokey');
	    } catch (err) {
		console.error('thunkCalcWriteDepositAmount err = ' + err);
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
);

export const thunkWriteContract = createAsyncThunk(
    'novaWriteContract/thunkWriteContract',
    async (arg, thunkAPI) => {
        console.log('im in the thunkWriteContract thunk');
	const state = thunkAPI.getState();
	return new Promise(async (resolve, reject) => {
	    try {
		const securityId       = state.novaSecurity.security.id;
		const expirationIdx    = state.novaContractSelector.selectedExpirationIdx;
		const strikePriceIdx   = state.novaContractSelector.selectedStrikePriceIdx;
		const strikePriceBN    = state.novaContractSelector.selectedStrikePriceBN;
		const contractAmountBN = state.novaWriteContract.writeContractAmountWeiBN;
		const contractMemoId = await novaEther.getContractMemoId(securityId, expirationIdx, strikePriceIdx);
		console.log('thunkWriteContract: contractMemoId = ' + contractMemoId);
		if (!!contractMemoId && !!parseInt(contractMemoId)) {
		    // verify strikeprice
		    const contractMemo = await novaEther.getContractMemo(contractMemoId);
		    if (!strikePriceBN.eq(contractMemo.strikePriceBN))
			throw('Strike-Price has changed');
		    console.log('thunkWriteContract - existing: contractAmount = ' + contractAmountBN.toString(10));
		    const txid = await novaEther.writeOptionExistingMemo(contractMemoId, contractAmountBN);
		} else {
		    console.log('thunkWriteContract - new: contractAmount = ' + contractAmountBN.toString(10));
		    const txid = await novaEther.writeOptionNewMemo(common.numberToBN(securityId), expirationIdx, strikePriceIdx, strikePriceBN, contractAmountBN);
		}
		thunkAPI.dispatch(thunkCheckBalances());
		thunkAPI.dispatch(thunkGetContractMemo({ securityId:     securityId,
							 expirationIdx:  expirationIdx,
							 strikePriceIdx: strikePriceIdx }));
		thunkAPI.dispatch(setWriteAmount({ contractAmount: '', securityAmount: '' }));
		thunkAPI.dispatch(thunkCalcWriteDepositAmount({ }));
		resolve('thunkWriteContract: okey dokey');
	    } catch (err) {
		console.error('thunkWriteContract err = ' + err);
		// for some reason rejectWithValue is ineffective when a metamask transaction is rejected. hence rejectHack.
		thunkAPI.dispatch(rejectHack());
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
)

function setPending(state, set) {
    console.log("setPending(state, " + set + ") cnt = " + state.userOrdersPendingCnt);
    if (set) {
	++state.writeContractPendingCnt;
	state.writeContractIsPending = true;
    } else {
	if (--state.writeContractPendingCnt <= 0) {
	    state.writeContractPendingCnt = 0
	    state.writeContractIsPending = false;
	}
    }
}

export const novaWriteContractSlice = createSlice({
    name: 'novaWriteContract',
    initialState: {
	changeMarker: 0,
	writeContractMode: 'write',
	writeContractAmount: 0.0,
	writeContractAmountWeiBN: null,
	writeSecurityAmount: 0.0,
	writeDepositAmount: 0.0,
	writeDepositAmountWeiBN: null,
	writeContractPendingCnt: 0,
	writeContractIsPending: false,
    },
    reducers: {
	setWriteMode: (state, action) => {
	    state.writeContractMode = action.payload;
	},
	setWriteAmount: (state, action) => {
	    state.writeContractAmount = action.payload.contractAmount;
	    state.writeSecurityAmount = action.payload.securityAmount;
	    state.writeContractAmountWeiBN = !!state.writeContractAmount ? ether.convertDecimalToWeiBN(state.writeContractAmount.toString()) : null;
	},
	setWriteDepositAmount: (state, action) => {
	    state.writeDepositAmount = action.payload.depositAmount;
	    state.writeDepositAmountWeiBN = action.payload.depositAmountWeiBN;
	},
	rejectHack: (state, action) => {
	    // for some reason rejectWithValue is ineffective when a metamask transaction is rejected. hence rejectHack.
	    setPending(state, false);
	},
    },
    extraReducers: {
	// Add reducers for additional action types here, and handle loading state as needed
	// thunks will have X.pending, X.fulfilled, X.rejected
	[thunkWriteContract.pending]:   (state, action) => { setPending(state, true);  },
	[thunkWriteContract.fulfilled]: (state, action) => { setPending(state, false); },
	[thunkWriteContract.rejected]:  (state, action) => { setPending(state, false); },
    }
})

export const {
    setWriteMode,
    setWriteAmount,
    setWriteDepositAmount,
    rejectHack,
} = novaWriteContractSlice.actions;

export const selectNovaWriteContract = state => state.novaWriteContract;
export default novaWriteContractSlice.reducer;
