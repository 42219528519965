import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// needed for async when using bable
const regeneratorRuntime = require("regenerator-runtime");
const common = require('../lib/common');
const ether = require('../lib/ether');
const novaEther = require('../lib/novaEther');
const to = require('../lib/to');
const BN = require("bn.js");


// create the thunk
// note arg is the argument password to the created thunk fcn
// note thunkAPI = { dispatch, getState, extra, rejectWithValue }
// where extra is the "extra argument" given to the thunk middleware on setup, if available
//
let periodicDutiesTimer = null;


async function checkAllApprovals(dispatch, getState) {
    let haveDaiApproval = false;
    let haveWethApproval = false;
    let haveWbtcApproval = false;
    const oneTeraEthBN = new BN(ether.teraEtherHex, 16);
    if (!!ether.LOCAL_ETH_ADDR) {
	const daiAllowanceWei = await novaEther.getDaiAllowance(ether.LOCAL_ETH_ADDR, novaEther.UTIL_ADDR);
	const daiAllowanceWeiBN = common.numberToBN(daiAllowanceWei);
	haveDaiApproval = daiAllowanceWeiBN.gt(oneTeraEthBN) ? true : false;
	const wethAllowanceWei = await novaEther.getWethAllowance(ether.LOCAL_ETH_ADDR, novaEther.UTIL_ADDR);
	const wethAllowanceWeiBN = common.numberToBN(wethAllowanceWei);
	haveWethApproval = wethAllowanceWeiBN.gt(oneTeraEthBN) ? true : false;
	const wbtcAllowanceWei = await novaEther.getWbtcAllowance(ether.LOCAL_ETH_ADDR, novaEther.UTIL_ADDR);
	const wbtcAllowanceWeiBN = common.numberToBN(wbtcAllowanceWei);
	haveWbtcApproval = wbtcAllowanceWeiBN.gt(oneTeraEthBN) ? true : false;
    }
    dispatch(setApprovals({ dai: haveDaiApproval, weth: haveWethApproval, wbtc: haveWbtcApproval }));
}


async function checkBalances(dispatch, getState) {
    const daiBalanceWei = await ether.getERC20Balance(ether.LOCAL_ETH_ADDR, novaEther.DAI_ADDR, 'wei');
    const daiBalanceWeiBN = common.numberToBN(daiBalanceWei);
    console.log('checkBalances: daiBalanceWeiBN = ' + JSON.stringify(daiBalanceWeiBN) + ', type = ' + typeof daiBalanceWeiBN);
    const daiBalance = ether.convertWeiToDecimal(daiBalanceWei, 2);
    if (!getState().wallet.daiBalanceWeiBN || !getState().wallet.daiBalanceWeiBN.eq(daiBalanceWeiBN))
	dispatch(setDaiBalance({ daiBalanceWeiBN: daiBalanceWeiBN, daiBalance: daiBalance }));
    const wethBalanceWei = await ether.getERC20Balance(ether.LOCAL_ETH_ADDR, novaEther.WETH_ADDR, 'wei');
    const wethBalanceWeiBN = common.numberToBN(wethBalanceWei);
    console.log('checkBalances: wethBalanceWeiBN = ' + JSON.stringify(wethBalanceWeiBN) + ', type = ' + typeof wethBalanceWeiBN);
    const wethBalance = ether.convertWeiToDecimal(wethBalanceWei, 2);
    if (!getState().wallet.wethBalanceWeiBN || !getState().wallet.wethBalanceWeiBN.eq(wethBalanceWeiBN))
	dispatch(setWethBalance({ wethBalanceWeiBN: wethBalanceWeiBN, wethBalance: wethBalance }));

    const wbtcBalanceWei = await ether.getERC20Balance(ether.LOCAL_ETH_ADDR, novaEther.WBTC_ADDR, 'wei');
    const wbtcBalanceWeiBN = common.numberToBN(wbtcBalanceWei);
    console.log('checkBalances: wbtcBalanceWeiBN = ' + JSON.stringify(wbtcBalanceWeiBN) + ', type = ' + typeof wbtcBalanceWeiBN);
    const wbtcBalance = ether.convertWeiToDecimal(wbtcBalanceWei, 2);
    if (!getState().wallet.wbtcBalanceWeiBN || !getState().wallet.wbtcBalanceWeiBN.eq(wbtcBalanceWeiBN))
	dispatch(setWbtcBalance({ wbtcBalanceWeiBN: wbtcBalanceWeiBN, wbtcBalance: wbtcBalance }));
}

async function checkNetwork(dispatch, getState) {
    const newNetworkName = await ether.init();
    if (newNetworkName != common.networkName) {
	common.networkName = newNetworkName;
	console.log('network: ' + newNetworkName);
	novaEther.init(ether.chainId);
	console.log('novaEther been initialized');
	ether.LOCAL_ETH_ADDR = null;
    }
    // set account (fill ethereum address) & addrStr (address to display)
    const [err, accounts] = await to.handle(common.provider.request({ method: 'eth_requestAccounts' }));
    if (!!err) {
	dispatch(setConnected(false));
	ether.LOCAL_ETH_ADDR = null;
	dispatch(setAddress(''));
	dispatch(setDisplayAddress(''));
	dispatch(setDaiBalance(0.0));
	dispatch(setWethBalance(0.0));
	dispatch(setWbtcBalance(0.0));
	if (!!periodicDutiesTimer) {
	    clearTimeout(periodicDutiesTimer);
	    periodicDutiesTimer = null;
	}
    } else {
	if (accounts[0] != ether.LOCAL_ETH_ADDR) {
	    console.log('address = ' + accounts[0]);
	    ether.LOCAL_ETH_ADDR = accounts[0];
	    dispatch(setAddress(ether.LOCAL_ETH_ADDR));
    	    const [err, name] = await to.handle(ether.ensReverseLookup(accounts[0]));
	    if (!!err)
		console.log('error in ens reverse lookup: ' + err);
	    const addrStr = common.abbreviateAddrForEns(accounts[0], name, 10, 8);
	    dispatch(setDisplayAddress(addrStr));
	    await checkAllApprovals(dispatch, getState);
	}
	await checkBalances(dispatch, getState);
	dispatch(setConnected(true));
	periodicDutiesTimer = setTimeout(function() {
	    checkNetwork(dispatch, getState);
	}, 20000);
    }
}


export const thunkConnectMetamask = createAsyncThunk(
    'wallet/thunkConnectMetamask',
    async (arg, thunkAPI) => {
        console.log('at least im in the thunk');
	return new Promise(async (resolve, reject) => {
	    try {
		if (typeof window.ethereum === 'undefined') {
		    const err = 'MetaMask is not installed!';
		    console.log(err);
		    thunkAPI.rejectWithValue(err);
		    return;
		}
		console.log('MetaMask is installed!');
		// init ethereum provider, common.web3
		ether.initProvider('metamask');
		await checkNetwork(thunkAPI.dispatch, thunkAPI.getState);
		if (!!periodicDutiesTimer)
		    clearTimeout(periodicDutiesTimer);
		periodicDutiesTimer = setTimeout(function() {
		    checkNetwork(thunkAPI.dispatch, thunkAPI.getState);
		}, 20000);
		resolve('okey dokey');
	    } catch (err) {
		console.error('thunkConnectMetamask err = ' + err);
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
)


export const thunkCheckApprovals = createAsyncThunk(
    'wallet/thunkCheckApprovals',
    async (arg, thunkAPI) => {
        console.log('im in the checkApprovals thunk');
	return new Promise(async (resolve, reject) => {
	    try {
		await checkApprovals(dispatch, getState);
		resolve('thunkCheckApprovals: okey dokey');
	    } catch (err) {
		console.error('thunkCheckApprovals err = ' + err);
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
);

export const thunkCheckBalances = createAsyncThunk(
    'wallet/thunkCheckBalances',
    async (arg, thunkAPI) => {
        console.log('im in the thunkCheckBalances thunk');
	return new Promise(async (resolve, reject) => {
	    try {
		await checkBalances(thunkAPI.dispatch, thunkAPI.getState);
		resolve('okey dokey');
	    } catch (err) {
		console.error('thunkCheckBalances err = ' + err);
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
)

export const thunkApproveDai = createAsyncThunk(
    'wallet/thunkApproveDai',
    async (arg, thunkAPI) => {
        console.log('im in the thunkApproveDai thunk');
	return new Promise(async (resolve, reject) => {
	    try {
		let daiApprovalBN = new BN("0");
		if (arg.doSet) {
		    daiApprovalBN = new BN(ether.teraEtherHex, 16);
		    daiApprovalBN.imuln(1000);
		}
		const txid = await novaEther.setDaiApproval(daiApprovalBN.toString(10), novaEther.UTIL_ADDR);
		console.log('txid = ' + txid);
		//receipt = await common.waitForTXID(txid, (c) => process.stdout.write(c)).then(process.stdout.write('\r'));
		//const receipt = await common.waitForTXID(txid, null);
		await checkAllApprovals(thunkAPI.dispatch, thunkAPI.getState);
		resolve('thunkApproveDai: okey dokey');
	    } catch (err) {
		console.error('thunkApproveDai err = ' + err);
		// for some reason rejectWithValue is ineffective when a metamask transaction is rejected. hence rejectHack.
		thunkAPI.dispatch(rejectHack());
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
);

export const thunkApproveWeth = createAsyncThunk(
    'wallet/thunkApproveWeth',
    async (arg, thunkAPI) => {
        console.log('im in the thunkApproveWeth thunk');
	return new Promise(async (resolve, reject) => {
	    try {
		let wethApprovalBN = new BN('0');
		if (arg.doSet) {
		    wethApprovalBN = new BN(ether.teraEtherHex, 16);
		    wethApprovalBN.imuln(1000);
		}
		const txid = await novaEther.setWethApproval(wethApprovalBN.toString(10), novaEther.UTIL_ADDR);
		//const receipt = await common.waitForTXID(txid, null);
		await checkAllApprovals(thunkAPI.dispatch, thunkAPI.getState);
		resolve('thunkApproveWeth: okey dokey');
	    } catch (err) {
		console.error('thunkApproveWeth: err = ' + err);
		// for some reason rejectWithValue is ineffective when a metamask transaction is rejected. hence rejectHack.
		thunkAPI.dispatch(rejectHack());
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
);

export const thunkApproveWbtc = createAsyncThunk(
    'wallet/thunkApproveWbtc',
    async (arg, thunkAPI) => {
        console.log('im in the thunkApproveWbtc thunk');
	return new Promise(async (resolve, reject) => {
	    try {
		let wbtcApprovalBN = new BN('0');
		if (arg.doSet) {
		    wbtcApprovalBN = new BN(ether.teraEtherHex, 16);
		    wbtcApprovalBN.imuln(1000);
		}
		const txid = await novaEther.setWbtcApproval(wbtcApprovalBN.toString(10), novaEther.UTIL_ADDR);
		//const receipt = await common.waitForTXID(txid, null);
		await checkAllApprovals(thunkAPI.dispatch, thunkAPI.getState);
		resolve('thunkApproveWbtc: okey dokey');
	    } catch (err) {
		console.error('thunkApproveWbtc: err = ' + err);
		// for some reason rejectWithValue is ineffective when a metamask transaction is rejected. hence rejectHack.
		thunkAPI.dispatch(rejectHack());
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
);



/*
   ether.getBalance(common.web3.eth.accounts[0], 'ether', function(err, balance) {
        const balanceArea = document.getElementById('balanceArea');
        console.log('balance (eth) = ' + balance);
        const balanceETH = parseFloat(balance).toFixed(6);
        balanceArea.value = '  Eth Balance: ' + balanceETH.toString(10) + ' Eth';
    });
    ether.getNetwork(function(err, network) {
        const networkArea = document.getElementById('networkArea');
        if (!!err) {
            networkArea.value = 'Error: ' + err;
        } else {
            networkArea.value = 'Network: ' + network;
            if (network.startsWith('Mainnet'))
                networkArea.className = (networkArea.className).replace('attention', '');
            else if (networkArea.className.indexOf(' attention' < 0))
                networkArea.className += ' attention';
            err = catEther.setNetwork(network);
            if (!err)
                err = mtEther.setNetwork(network);
            if (!err)
                err = meEther.setNetwork(network);
            if (!!err) {
                alert(err)
                return;
            }
*/


function setPending(state, set) {
    console.log("setPending(state, " + set + ") cnt = " + state.walletInfoPendingCnt);
    if (set) {
	++state.walletInfoPendingCnt;
	state.walletInfoIsPending = true;
    } else {
	if (--state.walletInfoPendingCnt <= 0) {
	    state.walletInfoPendingCnt = 0
	    state.walletInfoIsPending = false;
	}
    }
}

export const walletSlice = createSlice({
    name: 'wallet',
    initialState: {
	changeMarker: 0,
	connected: false,
	address: '',
	displayAddress: '',
	error: '',
	daiBalance: 0.0,
	daiBalanceWeiBN: null,
	wethBalance: 0.0,
	wethBalanceWeiBN: null,
	wbtcBalance: 0.0,
	wbtcBalanceWeiBN: null,
	haveDaiApproval: false,
	haveWethApproval: false,
	haveWbtcApproval: false,
	walletInfoPendingCnt: 0,
	walletInfoIsPending: false,
    },
    reducers: {
	setConnected: (state, action) => {
	    // Redux Toolkit allows us to write "mutating" logic in reducers. It
	    // doesn't actually mutate the state because it uses the immer library,
	    // which detects changes to a "draft state" and produces a brand new
	    // immutable state based off those changes
	    const changed = (state.connected != action.payload)
	    state.connected = action.payload;
	    if (changed)
		++state.changeMarker;
	},
	setAddress:        (state, action) => {
	    state.address = action.payload;
	    ++state.changeMarker;
	},
	setDisplayAddress: (state, action) => {
	    state.displayAddress = action.payload;
	    ++state.changeMarker;
	},
	setDaiBalance:     (state, action) => {
	    state.daiBalanceWeiBN = action.payload.daiBalanceWeiBN;
	    state.daiBalance      = action.payload.daiBalance;
	    ++state.changeMarker;
	},
	setWethBalance:    (state, action) => {
	    state.wethBalanceWeiBN = action.payload.wethBalanceWeiBN;
	    state.wethBalance      = action.payload.wethBalance;
	    ++state.changeMarker;
	},
	setWbtcBalance:    (state, action) => {
	    state.wbtcBalanceWeiBN = action.payload.wbtcBalanceWeiBN;
	    state.wbtcBalance      = action.payload.wbtcBalance;
	    ++state.changeMarker;
	},
	setApprovals:      (state, action) => {
	    state.haveDaiApproval  = action.payload.dai;
	    state.haveWethApproval = action.payload.weth;
	    state.haveWbtcApproval = action.payload.wbtc;
	    ++state.changeMarker;
	},
	rejectHack:      (state, action) => {
	    // for some reason rejectWithValue is ineffective when a metamask transaction is rejected. hence rejectHack.
	    setPending(state, false);
	},
    },
    extraReducers: {
	// Add reducers for additional action types here, and handle loading state as needed
	// thunks will have X.pending, X.fulfilled, X.rejected
	[thunkConnectMetamask.pending]:   (state, action) => { setPending(state, true);  },
	[thunkConnectMetamask.fulfilled]: (state, action) => { setPending(state, false); },
	[thunkConnectMetamask.rejected]:  (state, action) => { setPending(state, false); },
	[thunkCheckApprovals.pending]:    (state, action) => { setPending(state, true);  },
	[thunkCheckApprovals.fulfilled]:  (state, action) => { setPending(state, false); },
	[thunkCheckApprovals.rejected]:   (state, action) => { setPending(state, false); },
	[thunkCheckBalances.pending]:     (state, action) => { setPending(state, true);  },
	[thunkCheckBalances.fulfilled]:   (state, action) => { setPending(state, false); },
	[thunkCheckBalances.rejected]:    (state, action) => { setPending(state, false); },
	[thunkApproveDai.pending]:        (state, action) => { setPending(state, true);  },
	[thunkApproveDai.fulfilled]:      (state, action) => { setPending(state, false); },
	[thunkApproveDai.rejected]:       (state, action) => { setPending(state, false); },
	[thunkApproveWeth.pending]:       (state, action) => { setPending(state, true);  },
	[thunkApproveWeth.fulfilled]:     (state, action) => { setPending(state, false); },
	[thunkApproveWeth.rejected]:      (state, action) => { setPending(state, false); },
	[thunkApproveWbtc.pending]:       (state, action) => { setPending(state, true);  },
	[thunkApproveWbtc.fulfilled]:     (state, action) => { setPending(state, false); },
	[thunkApproveWbtc.rejected]:      (state, action) => { setPending(state, false); },
    },
})


export const {
    setConnected,
    setAddress,
    setDisplayAddress,
    setDaiBalance,
    setWethBalance,
    setWbtcBalance,
    setApprovals,
    rejectHack,
} = walletSlice.actions;


export const selectWallet = state => state.wallet;
export default walletSlice.reducer;
