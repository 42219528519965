import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// needed for async when using bable
const regeneratorRuntime = require("regenerator-runtime");
const common = require('../lib/common');
const ether = require('../lib/ether');
const novaEther = require('../lib/novaEther');
const to = require('../lib/to');
const BN = require("bn.js");


// --------------------------------------------------------------------------------------------------------------------------
// thunks
// --------------------------------------------------------------------------------------------------------------------------

//
// get the orderbook
//
export const thunkGetOrderBook = createAsyncThunk(
    'novaOrderBook/thunkGetOrderBook',
    async (arg, thunkAPI) => {
        console.log('im in the thunkGetOrderBook thunk');
	const state = thunkAPI.getState();
	return new Promise(async (resolve, reject) => {
	    try {
		const maxResults     = arg.maxResults;
		const isBid          = arg.isBid;
		console.log('thunkGetOrderBook: pre');
		if (!state.novaContractMemo.contractMemo) {
		    thunkAPI.dispatch(setOrderBook({ isBid: isBid, orders: [] }));
		} else {
		    const contractMemoId = state.novaContractMemo.contractMemoId;
		    console.log('thunkGetOrderBook: contractMemoId = ' + contractMemoId);
		    const ordersInfo = await novaEther.getOrders(contractMemoId, maxResults, isBid);
		    console.log('thunkGetOrderBook: post');
		    if (isBid) {
			const orderBookBids = [];
			console.log('thunkGetOrderBook: got ' + ordersInfo.count + ' bids');
			for (let i = 0; i < ordersInfo.count; ++i) {
			    const orderInfo = await novaEther.getOpenOrder(ordersInfo.orderIds[i]);
			    const prevOrderInfo = (orderBookBids.length > 0) ? orderBookBids[orderBookBids.length - 1] : null;
			    if (!!prevOrderInfo && orderInfo.priceBN.eq(prevOrderInfo.priceBN)) {
				prevOrderInfo.amountBN.iadd(orderInfo.amountBN);
				orderBookBids.push(null);
			    } else {
				orderBookBids.push(orderInfo);
			    }
			}
			thunkAPI.dispatch(setOrderBook({ isBid: true, orders: orderBookBids }));
		    } else {
			const orderBookAsks = [];
			console.log('thunkGetOrderBook: got ' + ordersInfo.count + ' asks');
			for (let i = 0; i < ordersInfo.count; ++i) {
			    const orderInfo = await novaEther.getOpenOrder(ordersInfo.orderIds[i]);
			    const prevOrderInfo = (orderBookAsks.length > 0) ? orderBookAsks[orderBookAsks.length - 1] : null;
			    if (!!prevOrderInfo && orderInfo.priceBN.eq(prevOrderInfo.priceBN)) {
				prevOrderInfo.amountBN.iadd(orderInfo.amountBN);
				orderBookAsks.push(null);
			    } else {
				orderBookAsks.push(orderInfo);
			    }
			}
			console.log('thunkGetOrderBook: got ' + orderBookAsks.length + ' asks');
			thunkAPI.dispatch(setOrderBook({ isBid: false, orders: orderBookAsks }));
		    }
		}
		resolve('thunkGetOrderBook: okey dokey');
	    } catch (err) {
		console.error('thunkGetOrderBook err = ' + err);
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
)

export const novaOrderBookSlice = createSlice({
    name: 'novaOrderBook',
    initialState: {
	changeMarker: 0,
	orderBookBids: [],
	orderBookAsks: [],
	orderBookPendingCnt: 0,
	orderBookIsPending: false,
    },
    reducers: {
	setOrderBook: (state, action) => {
	    if (action.payload.isBid)
		state.orderBookBids = action.payload.orders;
	    else
		state.orderBookAsks = action.payload.orders;
	    ++state.changeMarker;
	},
    },
    extraReducers: {
	// Add reducers for additional action types here, and handle loading state as needed
	// thunks will have X.pending, X.fulfilled, X.rejected
	[thunkGetOrderBook.pending]:   (state, action) => {
	    ++state.orderBookPendingCnt;
	    state.orderBookIsPending = true;
	},
	[thunkGetOrderBook.fulfilled]: (state, action) => {
	    if (--state.orderBookPendingCnt <= 0) {
		state.orderBookPendingCnt = 0
		state.orderBookIsPending = false;
	    }
	},
	[thunkGetOrderBook.rejected]: (state, action) => {
	    if (--state.orderBookPendingCnt <= 0) {
		state.orderBookPendingCnt = 0
		state.orderBookIsPending = false;
	    }
	},
    }
})

export const {
    setOrderBook,
} = novaOrderBookSlice.actions;


export const selectNovaOrderBook = state => state.novaOrderBook;
export default novaOrderBookSlice.reducer;
