import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import '../css/common.css';
import { setMode, selectPage } from "../features/pageSlice";

const common = require('../lib/common');


const SelectContractButton = () => {

    const page = useSelector(selectPage);

    const beenRendered = !!document.getElementById('selectContractButtonDiv');
    if (beenRendered) {
	if (page.pageMode == "select")
	    common.replaceElemClassFromTo("selectContractButtonDiv", "visible", "selected", false);
	else
	    common.replaceElemClassFromTo("selectContractButtonDiv", "selected", "visible", false);
    }

    const dispatch = useDispatch();
    const onSubmit = () => {
	dispatch(setMode('select'))
    };

    return (
            <button id="selectContractButtonDiv" type="button" className="plank panel pageModeButton selected" onClick={onSubmit}>Select contract</button>
    );
}

export default SelectContractButton;
