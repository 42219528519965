import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPage, setMode }      from "./features/pageSlice";

import './css/common.css';
import ConnectWalletButton from './components/ConnectWalletButton'
import ViewMyContractsButton from './components/ViewMyContractsButton'
import ViewMyActivityButton from './components/ViewMyActivityButton'
import SelectContractButton from './components/SelectContractButton'
import WriteContractButton from './components/WriteContractButton'
import TradeContractButton from './components/TradeContractButton'
import SelectContractWidget from './components/SelectContractWidget'
import SelectedContractWidget from './components/SelectedContractWidget'
import WriteContractWidget from './components/WriteContractWidget'
import OptionsListWidget from './components/OptionsListWidget'

import PriceChart from './components/PriceChart'
import TradeHistory from './components/TradeHistory'
import PlaceOrder from './components/PlaceOrder'
import OrderBook from './components/OrderBook'
import OrdersListWidget from './components/OrdersListWidget'

const common = require('./lib/common');


function TradePage() {
    const page = useSelector(selectPage);
    const dispatch = useDispatch();

    const beenRendered = !!document.getElementById('rootGrid');
    if (beenRendered) {
	if (!!page.isSpinning)
	    common.replaceElemClassFromTo("waitIcon", "hidden", "visible");
	else
	    common.replaceElemClassFromTo("waitIcon", "visible", "hidden");
    }

    const onSubmit = () => {
	dispatch(setMode('somethingelse'))
    };

    return (
            <div id="rootGrid">
              <div id="waitIcon" className="hidden"/>
	      <div id="topArea">
		 <button className="topAreaButton" onClick={onSubmit}>About NovaDex</button>
		 <button className="topAreaButton">Trading Pairs</button>
		 <button className="topAreaButton">Your First Call Option</button>
		 <button className="topAreaButton">Your First Put Option</button>
		 <div id="titleDiv">Nova DEX</div>
		 <ConnectWalletButton/>
	       </div>
	       <div id="middleLeftArea">
		 <ViewMyContractsButton/>
		 <ViewMyActivityButton/>
	       </div>
	       <div id="middleRightArea">
		 <SelectContractButton/>
		 <WriteContractButton />
		 <TradeContractButton/>
	         <SelectedContractWidget />
		 <SelectContractWidget />
                 <WriteContractWidget />
		 <OptionsListWidget />
		 <PlaceOrder />
		 <OrderBook />
		 <PriceChart />
		 <TradeHistory />
		 <OrdersListWidget />
	       </div>
	     </div>
    );
}

export default TradePage;
