import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import '../css/common.css';
import { selectPage }                           from "../features/pageSlice";
import { selectNovaSecurity }                   from "../features/novaSecuritySlice";
import { selectNovaContractSelector,
	 effectExpirationIdx }                  from "../features/novaContractSelectorSlice";
import { noContractsToNoSecurities }            from "../features/novaContractMemoSlice";
const ether = require('../lib/ether');
const novaEther = require('../lib/novaEther');
const common = require('../lib/common');
const BN = require("bn.js");


const dates = [ { id: 0, date: "invalid date", interval: 0, openInterest: "unknown", onClick: null, checked: false },
		{ id: 1, date: "invalid date", interval: 0, openInterest: "unknown", onClick: null, checked: false },
		{ id: 2, date: "invalid date", interval: 0, openInterest: "unknown", onClick: null, checked: false },
		{ id: 3, date: "invalid date", interval: 0, openInterest: "unknown", onClick: null, checked: false },
		{ id: 4, date: "invalid date", interval: 0, openInterest: "unknown", onClick: null, checked: false },
		{ id: 5, date: "invalid date", interval: 0, openInterest: "unknown", onClick: null, checked: false }
		];

function setIdxCheckedValue(idx, isChecked) {
    const elem = document.getElementById(dates[idx].id);
    if (!!elem) {
	elem.checked = isChecked;
	dates[idx].checked = isChecked;
    }
}


const ExpirationSelector = () => {
    const page = useSelector(selectPage);
    const novaSecurity = useSelector(selectNovaSecurity);
    const novaContractSelector = useSelector(selectNovaContractSelector);
    const security = novaSecurity.security;
    const dispatch = useDispatch();

    function clickHandler() {
	const buttons = document.getElementsByName("selectExpiration");
	for(let i = 0; i < buttons.length; ++i) {
            if(buttons[i].checked == true)
		dispatch(effectExpirationIdx(dates[i].interval));
	}
    };

    if (page.pageMode == "select") {
	if (!!security) {
	    const nowSec = (new Date).getTime() / 1000;
	    const startIntervalIdx = novaSecurity.startIntervalIdx;
	    const intervalBN = security.expirationIntervalBN;
	    const dateBN = security.expirationBaseBN.clone();
	    dateBN.iadd(intervalBN.muln(startIntervalIdx))

	    let foundIdx = 0;
	    for (let i = 0; i < 6; ++i) {
		dates[i].id = "expSelector" + i;
		dates[i].checked = false;
		const date = new Date();
		date.setTime(dateBN.muln(1000).toString(10));
		//<td>Tue Sept 29 2020</td>
		dates[i].date = common.days[date.getUTCDay()] + ' ' + common.months[date.getUTCMonth()] + ' ' +
		    common.leftPadTo(date.getUTCDate().toString(), 2, '0') + ' ' + date.getUTCFullYear();
		dates[i].interval = startIntervalIdx + i;
		let openInterest = "unknown";
		if (!!novaSecurity.totalInterestBNs) {
		    const openInterestBN = novaSecurity.totalInterestBNs[i];
		    const openInterestMaybeDecimals = ether.convertWeiToDecimal(openInterestBN.toString(10), 3);
		    const openInterestDecimal3 = parseFloat(openInterestMaybeDecimals).toFixed(3);
		    const noSecurities = noContractsToNoSecurities(novaSecurity.security, openInterestBN)
		    openInterest = openInterestDecimal3 + ' (' + noSecurities.decimal3 + ' ' + novaSecurity.securityName + ')';
		}
		dates[i].openInterest = openInterest;
		if (startIntervalIdx + i == novaContractSelector.selectedExpirationIdx)
		    foundIdx = i;
		dates[i].onClick = clickHandler;
		dateBN.iadd(intervalBN);
	    }
	    setIdxCheckedValue(foundIdx, true);
	    if (dates[foundIdx].interval != novaContractSelector.selectedExpirationIdx)
		dispatch(effectExpirationIdx(dates[foundIdx].interval));
	} else {
	    for (let i = 0; i < 6; ++i) {
		dates[i] = { id: "expSelector" + i, date: "invalid date", interval: 0, openInterest: "unknown", onClick: null, checked: false };
		setIdxCheckedValue(i, false);
	    }
	}
    }

    return (
          <div className="expirationSelectorTable">
            <form action="">
	      <table className="expirationSelectorTable">
		<tr>
		  <th>expiration</th>
		  <th>total open interest</th>
		  <th>select</th>
		</tr>
		{dates.map(date => (
				<tr>
				  <td className="expirationSelectorTableExpiration">{date.date}</td>
				  <td className="selectorTableOpenInterest">{date.openInterest}</td>
				  <td>
				    <input key={date.id} type="radio" id={date.id} value={date.id} name="selectExpiration" onClick={date.onClick} />
				  </td>
				</tr>
		))}
	      </table>
	    </form>
          </div>
	);
}

export default ExpirationSelector;
