import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { thunkCheckBalances }            from "../features/walletSlice";

// needed for async when using bable
const regeneratorRuntime = require("regenerator-runtime");
const common = require('../lib/common');
const ether = require('../lib/ether');
const novaEther = require('../lib/novaEther');
const to = require('../lib/to');
const BN = require("bn.js");


// utils
// convert number of contracts (BN) to 3 decimal fixed number of securities
export const noContractsToNoSecurities = (security, noContractsBN) => {
    const noSecuritiesWeiBN = noContractsBN.mul(security.tokensPerContractBN).div(ether.etherBN);
    const noSecuritiesMaybeDecimal = ether.convertWeiToDecimal(noSecuritiesWeiBN.toString(10), 3);
    const noSecuritiesDecimal3 = parseFloat(noSecuritiesMaybeDecimal).toFixed(3);
    return({ weiBN: noSecuritiesWeiBN, decimal3: noSecuritiesDecimal3 });
};

// can the current owner cancel a written contract
export const canCancel = (novaContractMemo) => {
    if (!!novaContractMemo.ownedInfo) {
	if (!novaContractMemo.ownedInfo.amountFreeBN.isZero()) {
	    return !novaContractMemo.ownedInfo.amountSellableBN.isZero()
	}
    }
    return false;
};

// can the current owner exercise a held contract
export const canExercise = (novaContractMemo) => {
    if (!!novaContractMemo.ownedInfo) {
	if (!novaContractMemo.ownedInfo.amountHeldBN.isZero()) {
	    return !novaContractMemo.ownedInfo.amountSellableBN.isZero()
	}
    }
    return false;
};


// thunks
// note arg is the argument password to the created thunk fcn
// note thunkAPI = { dispatch, getState, extra, rejectWithValue }
// where extra is the "extra argument" given to the thunk middleware on setup, if available
//
export const thunkGetContractMemo = createAsyncThunk(
    'novaContractMemo/thunkGetContractMemo',
    async (arg, thunkAPI) => {
        console.log('im in the thunkGetContractMemo thunk');
	const state = thunkAPI.getState();
	return new Promise(async (resolve, reject) => {
	    try {
		const securityId     = arg.securityId;
		const expirationIdx  = arg.expirationIdx;
		const strikePriceIdx = arg.strikePriceIdx;
		const contractMemoId = await novaEther.getContractMemoId(securityId, expirationIdx, strikePriceIdx);
		console.log('thunkGetContractMemo: security = ' + securityId);
		console.log('thunkGetContractMemo: expirationIdx = ' + expirationIdx);
		console.log('thunkGetContractMemo: strikePriceIdx = ' + strikePriceIdx);
		console.log('thunkGetContractMemo: contractMemoId = ' + contractMemoId);
		if (contractMemoId == 0) {
		    thunkAPI.dispatch(setContractMemo({ id: contractMemoId, contractMemo: null, ownedInfo: null }));
		} else {
		    const contractMemo = await novaEther.getContractMemo(contractMemoId);
		    const owner = state.wallet.address;
		    if (!owner) {
			thunkAPI.dispatch(setContractMemo({ id: contractMemoId, contractMemo: contractMemo, ownedInfo: null }));
		    } else {
			const ownedInfo = await novaEther.getOwnedContractMemo(contractMemoId, owner);
			thunkAPI.dispatch(setContractMemo({ id: contractMemoId, contractMemo: contractMemo, ownedInfo: ownedInfo }));
		    }
		}
		resolve('thunkGetContractMemo: okey dokey');
	    } catch (err) {
		console.error('thunkGetContractMemo err = ' + err);
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
);


async function getOrderHint(contractMemo, priceBN, isBid) {
    const ordersInfo = await novaEther.getOrders(contractMemo.id, 10, isBid);
    common.log('ordersInfo.count = ' + ordersInfo.count);
    common.log('ordersInfo.orderIds (len = ' + ordersInfo.orderIds.length + ') = ' + ordersInfo.orderIds);
    let hint = 0;
    // hint will be adjacent better bid
    for (let i = 0; i < ordersInfo.count; ++i) {
	const openOrderInfo = await novaEther.getOpenOrder(ordersInfo.orderIds[i]);
	if (( isBid && priceBN.gt(openOrderInfo.priceBN)) ||
	    (!isBid && priceBN.lt(openOrderInfo.priceBN)) )
	    break;
	hint = ordersInfo.orderIds[i];
    }
    common.log('hint = ' + hint);
    return(hint);
}

/*
 // to list held/written contracts
 //
export const thunkGetMyOrders = createAsyncThunk(
    'novaContractMemo/thunkGetMyOrders',
    async (arg, thunkAPI) => {
        console.log('im in the thunkGetMyOrders thunk');
	const state = thunkAPI.getState();
	return new Promise(async (resolve, reject) => {
	    try {
		const contractMemoId = state.novaContractMemo.contractMemoId;
		console.log('contractMemoId = ' + contractMemoId);
		const logs = await ether.getLogs({ fromBlock: 'earliest', toBlock: 'latest', address: novaEther.OPTIONCONTRACT_ADDR,
						   topics: [ novaEther.getOptionHolderEvTopic0(), common.numberToHex256(ether.LOCAL_ETH_ADDR) ] });
		console.log('got logs: ' + JSON.stringify(logs));
		for (let i = 0; i < logs.length; ++i) {
		    const optionHolderEv = await novaEther.parseOptionHolderEv(logs[i]);
		    const contractMemo = await novaEther.getContractMemo(optionHolderEv.contractMemoBN);
		    console.log('optionHolderEv.holder       => ' + optionHolderEv.holder);
		    console.log('should be:                     ' + ether.LOCAL_ETH_ADDR);
		    console.log('optionHolderEv.contractMemo => ' + optionHolderEv.contractMemoBN);
		    console.log('optionHolderEv.amount       => ' + optionHolderEv.amountBN + ' (' + ether.convertWeiToDecimal(optionHolderEv.amountBN) + ')');
		    console.log('optionHolderEv.date         => ' + optionHolderEv.date.toUTCString());
		    console.log('event                       => ' + novaEther.whatEventToStr(optionHolderEv.whatEventBN.toNumber()));
*/


export const novaContractMemoSlice = createSlice({
    name: 'novaContractMemo',
    initialState: {
	changeMarker: 0,
	contractMemoId: 0,
	contractMemo: null,
	ownedInfo: null,
	contractMemoIsPending: false,
	//
	doExpandOrderList: false,
	orderListType: 'all',
	userOrders: [],
    },
    reducers: {
	setContractMemo: (state, action) => {
	    state.contractMemoId = action.payload.id;
	    state.contractMemo = action.payload.contractMemo;
	    state.ownedInfo = action.payload.ownedInfo;
	    ++state.changeMarker;
	},
	setUserOrders: (state, action) => {
	    state.userOrders = action.payload;
	},
	setExpandOrderList: (state, action) => {
	    state.doExpandOrderList = action.payload;
	},
	setOrderListType: (state, action) => {
	    state.orderListType = action.payload;
	},
    },
    extraReducers: {
	// Add reducers for additional action types here, and handle loading state as needed
	// thunks will have X.pending, X.fulfilled, X.rejected
	[thunkGetContractMemo.pending]:   (state, action) => { state.contractMemoIsPending = true; },
	[thunkGetContractMemo.fulfilled]: (state, action) => { state.contractMemoIsPending = false; },
	[thunkGetContractMemo.rejected]:  (state, action) => { state.contractMemoIsPending = false; },
    }
})

export const {
    setContractMemo,
    setUserOrders,
    setExpandOrderList,
    setOrderListType,
} = novaContractMemoSlice.actions;


export const selectNovaContractMemo = state => state.novaContractMemo;
export default novaContractMemoSlice.reducer;
