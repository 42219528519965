import { configureStore }          from "@reduxjs/toolkit";
import pageReducer                 from "./features/pageSlice";
import walletReducer               from "./features/walletSlice";
import novaSecurityReducer         from "./features/novaSecuritySlice";
import novaContractMemoReducer     from "./features/novaContractMemoSlice";
import novaContractSelectorReducer from "./features/novaContractSelectorSlice";
import novaWriteContractReducer    from "./features/novaWriteContractSlice";
import novaOptionEventsReducer     from "./features/novaOptionEventsSlice";
import novaPlaceOrderReducer       from "./features/novaPlaceOrderSlice";
import novaOrderBookReducer        from "./features/novaOrderBookSlice";
import novaUserOrdersReducer       from "./features/novaUserOrdersSlice";
const thunk = require('redux-thunk').default;

//
// eg:
// group heading: page/setMode
// dispatching => action (eg { type: 'page/setMode', payload: 'trade' })
// next state  => full state info
//
const logger = store => next => action => {
  console.group(action.type)
  console.info('dispatching', action)
  let result = next(action)
  console.log('next state', store.getState())
  console.groupEnd()
  return result
}


export default configureStore({
  reducer: {
      page:                 pageReducer,
      wallet:               walletReducer,
      novaSecurity:         novaSecurityReducer,
      novaContractMemo:     novaContractMemoReducer,
      novaContractSelector: novaContractSelectorReducer,
      novaWriteContract:    novaWriteContractReducer,
      novaOptionEvents:     novaOptionEventsReducer,
      novaPlaceOrder:       novaPlaceOrderReducer,
      novaOrderBook:        novaOrderBookReducer,
      novaUserOrders:       novaUserOrdersReducer,
  },
  middleware: [
      thunk,
      logger,
  ],
});
