import React, { useState } from "react";
import { useDispatch, useSelector }   from "react-redux";

import '../css/common.css';
import { selectPage }                 from "../features/pageSlice";
import { selectWallet }               from "../features/walletSlice";
import { selectNovaSecurity }         from "../features/novaSecuritySlice";
import { selectNovaContractSelector } from "../features/novaContractSelectorSlice";
import { selectNovaContractMemo,
	 canExercise,
	 canCancel }                  from "../features/novaContractMemoSlice";
import { selectNovaWriteContract,
	 thunkCalcWriteDepositAmount,
	 thunkWriteContract,
	 setWriteMode,
	 setWriteAmount }             from "../features/novaWriteContractSlice";
const novaEther = require('../lib/novaEther');
const ether = require('../lib/ether');
const common = require('../lib/common');
const BN = require("bn.js");


class AmountDenom extends React.Component {
    render() {
        const id = "writeContractAmountDenom" + this.props.alt;
	return(
	       <p id={id}>{this.props.denom}</p>
	);
    }
}

let prevSelectedDisplay = "";

const WriteContractWidget = () => {
    const page = useSelector(selectPage);
    const wallet = useSelector(selectWallet);
    const novaSecurity = useSelector(selectNovaSecurity);
    const novaContractSelector = useSelector(selectNovaContractSelector);
    const novaContractMemo = useSelector(selectNovaContractMemo);
    const novaWriteContract = useSelector(selectNovaWriteContract);
    const dispatch = useDispatch();

    let modeHeading = 'Write Contract';
    let securityText = 'WETH';
    let toolTipText = '';
    let contractAmountDisplay = '';
    let contractAmountDisplayAlt = '';
    let depositMsg = '';
    let selectedDisplay = "Selected contract = No Option Contract Selected";
    let youMustMsg = "You need to connect your wallet";
    const security = novaSecurity.security;

    const contractsToSecurities = (contractAmount) => {
	const tokensPerContract = ether.convertWeiToDecimal(security.tokensPerContract, 3);
	const securityAmount = !!contractAmount ? common.fixedFloat(common.fixedFloat(contractAmount, 3) * tokensPerContract, 3) : 0;
	return({ contractAmount: contractAmount, securityAmount: securityAmount });
    };
    const securitiesToContracts = (securityAmount) => {
	const tokensPerContract = ether.convertWeiToDecimal(security.tokensPerContract, 3);
	const contractAmount = !!securityAmount ? common.fixedFloat(common.fixedFloat(securityAmount, 3) / tokensPerContract, 3) : 0;
	return({ contractAmount: contractAmount, securityAmount: securityAmount });
    }
    const onContractAmountInput = () => {
	const contractAmount = document.getElementById('writeContractAmountEntry').value;
	const amounts = contractsToSecurities(contractAmount);
	dispatch(setWriteAmount(amounts));
	dispatch(thunkCalcWriteDepositAmount({ }));
    };
    const onContractAmountBlur = () => {
	const contractAmount = common.fixedFloat(document.getElementById('writeContractAmountEntry').value, 3);
	const amounts = contractsToSecurities(contractAmount);
	dispatch(setWriteAmount(amounts));
	dispatch(thunkCalcWriteDepositAmount({ }));
    };
    const onContractAmountAltInput = () => {
	const securityAmount = document.getElementById('writeContractAmountEntryAlt').value;
	const amounts = securitiesToContracts(securityAmount);
	dispatch(setWriteAmount(amounts));
	dispatch(thunkCalcWriteDepositAmount({ }));
    };
    const onContractAmountAltBlur = () => {
	const securityAmount = common.fixedFloat(document.getElementById('writeContractAmountEntryAlt').value, 3);
	const rawAmounts = securitiesToContracts(securityAmount);
	const amounts = contractsToSecurities(rawAmounts.contractAmount);
	dispatch(setWriteAmount(amounts));
	dispatch(thunkCalcWriteDepositAmount({ }));
    };
    const onModeWrite = () => {
	console.log('onModeWrite');
	dispatch(setWriteMode('write'));
	dispatch(thunkCalcWriteDepositAmount({ }));
    };
    const onModeExercise = () => {
	console.log('onModeExercise');
	dispatch(setWriteMode('exercise'));
	dispatch(thunkCalcWriteDepositAmount({ }));
    };
    const onModeCancel = () => {
	console.log('onModeCancel');
	dispatch(setWriteMode('cancel'));
	dispatch(thunkCalcWriteDepositAmount({ }));
    };
    const onWriteExecute = () => {
	console.log('onWriteExecute');
	if (!!novaWriteContract.writeContractAmount) {
	    dispatch(thunkWriteContract({ }));
	}
    };


    const beenRendered = !!document.getElementById('writeContractWidgetDiv');
    if (beenRendered) {
	if (page.pageMode != "write") {
	    common.replaceElemClassFromTo("writeContractWidgetDiv", "visible", "hidden");
	} else {
	    common.replaceElemClassFromTo("writeContractWidgetDiv", "hidden", "visible");
	    if (!!novaWriteContract.writeContractIsPending)
		common.replaceElemClassFromTo("writeContractWaitIcon", "hidden", "visible");
	    else
		common.replaceElemClassFromTo("writeContractWaitIcon", "visible", "hidden");
	    if (!security || !novaContractSelector.selectedStrikePriceBN) {
		modeHeading = 'Write Contract';
		youMustMsg = "You need to select a contract";
		common.setButtonState("writeContractExecuteButton",  "Disabled");
		common.replaceElemClassFromTo("writeContractYouMustMsgDiv", "hidden", "visible");
	    } else {
		const intervalBN = security.expirationIntervalBN;
		const dateBN = security.expirationBaseBN.clone();
		dateBN.iadd(intervalBN.muln(novaContractSelector.selectedExpirationIdx));
		const date = new Date();
		date.setTime(dateBN.muln(1000).toString(10));
		const dateDisplay = common.days[date.getUTCDay()] + ' ' + common.months[date.getUTCMonth()] + ' ' +
		      common.leftPadTo(date.getUTCDate().toString(), 2, '0') + ' ' + date.getUTCFullYear() + ' ' +
		      common.leftPadTo(date.getUTCHours().toString(), 2, '0') + ":" +
		      common.leftPadTo(date.getUTCMinutes().toString(), 2, '0')  + ":" +
		      common.leftPadTo(date.getUTCSeconds().toString(), 2, '0');
		securityText = novaSecurity.wethSelected ? "WETH"  : "WBTC";
		//Call 100 WETH @ 525 exp: Tue Oct 27 2020 23:59:00 UTC
		const priceMaybeDecimals = ether.convertWeiToDecimal(novaContractSelector.selectedStrikePriceBN.toString(10), 2);
		const priceWith2Decimals = parseFloat(priceMaybeDecimals).toFixed(2);
		const tokensPerContract = parseInt(ether.convertWeiToDecimal(security.tokensPerContractBN.toString(10), 3));
		selectedDisplay =
		    (novaSecurity.callSelected ? "Call" : "Put") + " " +
		    tokensPerContract + " " +
		    securityText + " " +
		    "@ " + priceWith2Decimals + " " +
		    "exp: " + dateDisplay + " UTC";
		contractAmountDisplay = novaWriteContract.writeContractAmount.toString();
		contractAmountDisplayAlt = novaWriteContract.writeSecurityAmount.toString();
		if (prevSelectedDisplay.indexOf(selectedDisplay) != 0) {
		    prevSelectedDisplay = selectedDisplay;
		    onContractAmountBlur();
		} else {
		    if (novaSecurity.callSelected)
			depositMsg = 'Deposit amount will be ' + parseFloat(novaWriteContract.writeDepositAmount).toFixed(3) + ' ' + securityText;
		    else
			depositMsg = 'Deposit amount will be ' + parseFloat(novaWriteContract.writeDepositAmount).toFixed(2) + ' DAI';
		}
		console.log('WriteContractWidget: REDRAWING, wallet.connected = ' + wallet.connected);
		let secBalanceWeiBN = null;
		if (!!wallet.connected)
		    secBalanceWeiBN = novaSecurity.wethSelected ? wallet.wethBalanceWeiBN : wallet.wbtcBalanceWeiBN;

		//
		// deal with possible modes: write, exercise, cancel
		if (novaWriteContract.writeContractMode == 'write') {
		    modeHeading = 'Write Contract';
		    common.setButtonState("writeContractWriteButton", "Selected");
		    common.setButtonState("writeContractExerciseButton", canExercise(novaContractMemo) ? "Enabled" : "Disabled");
		    common.setButtonState("writeContractCancelButton", canCancel(novaContractMemo) ? "Enabled" : "Disabled");
		} else if (novaWriteContract.writeContractMode == 'exercise') {
		    modeHeading = 'Exercise Contract';
		    common.setButtonState("writeContractWriteButton", "Enabled");
		    common.setButtonState("writeContractExerciseButton", "Selected");
		    common.setButtonState("writeContractCancelButton", canCancel(novaContractMemo) ? "Enabled" : "Disabled");
		} else if (novaWriteContract.writeContractMode == 'cancel') {
		    modeHeading = 'Cancel Contract';
		    common.setButtonState("writeContractWriteButton", "Enabled");
		    common.setButtonState("writeContractExerciseButton", canExercise(novaContractMemo) ? "Enabled" : "Disabled");
		    common.setButtonState("writeContractCancelButton", "Selected");
		}

		//
		//
		if (!wallet.connected) {
		    youMustMsg = "You need to connect your wallet";
		    common.setButtonState("writeContractExecuteButton",  "Disabled");
		    common.replaceElemClassFromTo("writeContractYouMustMsgDiv", "hidden", "visible");
		} else if (novaSecurity.putSelected  && !wallet.haveDaiApproval                               ||
			   novaSecurity.callSelected && novaSecurity.wethSelected && !wallet.haveWethApproval ||
			   novaSecurity.callSelected && novaSecurity.wbtcSelected && !wallet.haveWbtcApproval ) {
		    const thingToApprove = novaSecurity.callSelected ? securityText : "DAI";
		    youMustMsg = "You need to approve " + thingToApprove + " for trading";
		    common.setButtonState("writeContractExecuteButton",  "Disabled");
		    common.replaceElemClassFromTo("writeContractYouMustMsgDiv", "hidden", "visible");
		} else if (novaWriteContract.writeDepositAmountWeiBN && !novaWriteContract.writeDepositAmountWeiBN.isZero() &&
			    ( novaSecurity.callSelected &&
			     (!secBalanceWeiBN || secBalanceWeiBN.lt(novaWriteContract.writeDepositAmountWeiBN))               ) ||
			    (!novaSecurity.callSelected &&
			     (!wallet.daiBalanceWeiBN || wallet.daiBalanceWeiBN.lt(novaWriteContract.writeDepositAmountWeiBN)) ) ) {
		    const depositThing = novaSecurity.callSelected ? securityText : "DAI";
		    youMustMsg = "You don\'t own enough " + depositThing + " to write this contract";
		    common.setButtonState("writeContractExecuteButton",  "Disabled");
		    common.replaceElemClassFromTo("writeContractYouMustMsgDiv", "hidden", "visible");
		} else if (!novaWriteContract.writeDepositAmountWeiBN || novaWriteContract.writeDepositAmountWeiBN.isZero()) {
		    common.setButtonState("writeContractExecuteButton",  "Disabled");
		    common.replaceElemClassFromTo("writeContractYouMustMsgDiv", "visible", "hidden");
		} else {
		    common.setButtonState("writeContractExecuteButton",  "Enabled");
		    common.replaceElemClassFromTo("writeContractYouMustMsgDiv", "visible", "hidden");
		}
	    }
	}
    }

    return (
	    <div id="writeContractWidgetDiv" class="plank panel hidden">
            <div id="writeContractWaitIcon" className="hidden"/>
	    <div className="panelTitle">
	      {modeHeading}
	      </div>
	      <div className="panelSubtitle">
		{selectedDisplay}
	      </div>
	    <div id="writeContractSubPanel">
	      <div id="writeContractButtonsDiv">
		<button id="writeContractWriteButton" type="button" className="button" onClick={onModeWrite}>Write</button>
		<button id="writeContractExerciseButton" type="button" className="button" onClick={onModeExercise}>Exercise</button>
		<button id="writeContractCancelButton" type="button" className="button" onClick={onModeCancel}>Cancel</button>
	      </div>

		<div id="writeContractAmountTripleLine">
	          <label id="writeContractAmountLabel" for="contract-amount-entry">Amount</label>
	    	  <input id="writeContractAmountEntry" placeholder="0" value={contractAmountDisplay} onInput={onContractAmountInput} onBlur={onContractAmountBlur}/>
	          <AmountDenom alt="" denom="Contracts"/>
	          <label id="writeContractAmountLabelAlt" for="security-amount-entry">For</label>
	          <input id="writeContractAmountEntryAlt" placeholder="0" value={contractAmountDisplayAlt} onInput={onContractAmountAltInput} onBlur={onContractAmountAltBlur}/>
		  <AmountDenom alt="Alt" denom={securityText}/>
		  <div id="writeContractDepositMsgDiv">
		    {depositMsg}
		  </div>
		</div>
		<div id="writeContractSubPanelBottomDiv">
	    	  <div id="writeContractYouMustMsgDiv" className="visible">
		    {youMustMsg}
		  </div>
		  <button id="writeContractExecuteButton" type="button" className="button" onClick={onWriteExecute}>{modeHeading}</button>
		</div>
	      </div>
	    </div>
	    );
}

export default WriteContractWidget;
