const React = require("react");
const ReactDOM = require("react-dom");
import { Provider } from 'react-redux';
import store from './store';
import TradePage from './TradePage';
import "./css/style.css";
import "./css/common.css";

const App = () => {
  return <div className="hello">hello world novadex</div>;
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <TradePage />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
