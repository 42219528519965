//
// this slice deals with getting the initial list of all securities; and collecting
// info pertinent to a selected security (ie: expirations, strike-prices, open interest)
// reducers are provided for selecting a particular security by underlying asset,
// expiration index, and strike-price.
//
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'


// needed for async when using bable
const regeneratorRuntime = require("regenerator-runtime");
const common = require('../lib/common');
const ether = require('../lib/ether');
const novaEther = require('../lib/novaEther');
const to = require('../lib/to');
const BN = require("bn.js");


// create the thunk
// note arg is the argument password to the created thunk fcn
// note thunkAPI = { dispatch, getState, extra, rejectWithValue }
// where extra is the "extra argument" given to the thunk middleware on setup, if available
//
let chainId = -1;
let securitiesCount = 0;
const DAILY   = 0;
const WEEKLY  = 1;
const MONTHLY = 2;
const DAILY_SECONDS   = 86400;
const WEEKLY_SECONDS  = 604800;
const MONTHLY_SECONDS = 2592000;

export const thunkGetSecurities = createAsyncThunk(
    'novaSecurity/thunkGetSecurities',
    async (arg, thunkAPI) => {
        console.log('im in the thunkGetSecurities thunk');
	return new Promise(async (resolve, reject) => {
	    try {
		if (typeof window.ethereum === 'undefined') {
		    const err = 'MetaMask is not installed!';
		    console.log(err);
		    thunkAPI.rejectWithValue(err);
		    return;
		}
		console.log('MetaMask is installed!');
		if (!common.provider) {
		    // init ethereum provider, common.web3
		    ether.initProvider('metamask');
		}
		if (chainId < 0 || chainId != ether.chainId) {
		    const networkName = await ether.init();
		    console.log('network: ' + networkName + ', ether.chainId = ' + ether.chainId);
		    chainId = ether.chainId;
		    novaEther.init(ether.chainId);
		    securitiesCount = 0;
		}
		if (securitiesCount == 0 && !!novaEther.OPTIONCONTRACT_ADDR) {
		    const wethAddr = common.numberToBN(novaEther.WETH_ADDR);
		    const wbtcAddr = common.numberToBN(novaEther.WBTC_ADDR);
		    securitiesCount = await novaEther.getSecuritiesCount();
		    for (let i = 0; i < securitiesCount; ++i) {
			const security = await novaEther.getSecurity(i + 1);
			if (!security.isReplaced) {
			    let period;
			    if (security.expirationInterval == DAILY_SECONDS)
				period = DAILY;
			    else if (security.expirationInterval == WEEKLY_SECONDS)
				period = WEEKLY;
			    else if (security.expirationInterval == MONTHLY_SECONDS)
				period = MONTHLY;
			    else {
				console.log('found security with invalid period: ' + JSON.stringify(security));
				continue;
			    }
			    const securityAddr = common.numberToBN(security.tokenAddr);
			    if      (!security.isPut && securityAddr.eq(wethAddr)) {
				thunkAPI.dispatch(setWethCallSecurity({ period: period, security: security }));
			    } else if ( security.isPut && securityAddr.eq(wethAddr)) {
				thunkAPI.dispatch(setWethPutSecurity({  period: period, security: security }));
			    } else if (!security.isPut && securityAddr.eq(wbtcAddr)) {
				thunkAPI.dispatch(setWbtcCallSecurity({ period: period, security: security }));
			    } else if ( security.isPut && securityAddr.eq(wbtcAddr)) {
				thunkAPI.dispatch(setWbtcPutSecurity({  period: period, security: security }));
			    }
			}
		    }
		    if (securitiesCount != 0)
			thunkAPI.dispatch(setSecurity());
		}
		resolve('thunkGetSecurities: okey dokey');
	    } catch (err) {
		console.log('thunkGetSecurities err = ' + err);
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
)


//
// refresh contract info for all conracts for the passed security. that includes
// things like openinterest & strike prices for upcoming epiration dates
//
export const thunkGetSelectedSecurityInfo = createAsyncThunk(
    'novaContractSelector/thunkGetSelectedSecurityInfo',
    async (arg, thunkAPI) => {
        console.log('im in the thunkGetSelectedSecurityInfo thunk');
	const state = thunkAPI.getState();
	return new Promise(async (resolve, reject) => {
	    try {
		if (typeof window.ethereum === 'undefined') {
		    const err = 'MetaMask is not installed!';
		    console.log(err);
		    thunkAPI.rejectWithValue(err);
		    return;
		}
		console.log('MetaMask is installed!');
		if (!common.provider) {
		    // init ethereum provider, common.web3
		    ether.initProvider('metamask');
		}
		if (chainId < 0 || chainId != ether.chainId) {
		    const networkName = await ether.init();
		    console.log('network: ' + networkName + ', ether.chainId = ' + ether.chainId);
		    chainId = ether.chainId;
		    novaEther.init(ether.chainId);
		}
		const nowSec = (new Date).getTime() / 1000;
 		const security = state.novaSecurity.security;
		const startIntervalIdx = novaEther.getNextIntervalIdx(security, nowSec);
		thunkAPI.dispatch(setStartIntervalIdx(startIntervalIdx));
		const openInterestBNs = [];
		const totalInterestBNs = [];
		if (!!novaEther.OPTIONCONTRACT_ADDR) {
		    for (let e = 0; e < 6; ++e) {
			const strikePriceInfos = [];
			const totalBN = new BN("0");
			for (let s = 0; s < 6; ++s) {
			    const contractMemoId = await novaEther.getContractMemoId(security.id, startIntervalIdx + e, s);
			    if (!contractMemoId) {
				strikePriceInfos.push(null);
			    } else {
				const contractMemo = await novaEther.getContractMemo(contractMemoId);
				strikePriceInfos.push(contractMemo.openInterestBN);
				totalBN.iadd(contractMemo.openInterestBN);
			    }
			}
			openInterestBNs.push(strikePriceInfos);
			totalInterestBNs.push(totalBN);
		    }
		    thunkAPI.dispatch(setOpenInterestBNs({ openInterestBNs: openInterestBNs, totalInterestBNs: totalInterestBNs }));
		}
		resolve('thunkGetSelectedSecurityInfo: okey dokey');
	    } catch (err) {
		console.log('thunkGetSelectedSecurityInfo err = ' + err);
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
)


function _setSecurity(state) {
    if (state.selectedPeriod == DAILY) {
	if (state.callSelected)
	    state.security = state.wethSelected ? state.dailyWethCallSecurity : state.dailyWbtcCallSecurity;
	else
	    state.security = state.wethSelected ? state.dailyWethPutSecurity  : state.dailyWbtcPutSecurity;
    } else if (state.selectedPeriod == WEEKLY) {
	if (state.callSelected)
	    state.security = state.wethSelected ? state.weeklyWethCallSecurity : state.weeklyWbtcCallSecurity;
	else
	    state.security = state.wethSelected ? state.weeklyWethPutSecurity  : state.weeklyWbtcPutSecurity;
    } else if (state.selectedPeriod == MONTHLY) {
	if (state.callSelected)
	    state.security = state.wethSelected ? state.monthlyWethCallSecurity : state.monthlyWbtcCallSecurity;
	else
	    state.security = state.wethSelected ? state.monthlyWethPutSecurity  : state.monthlyWbtcPutSecurity;
    }
}

export const novaSecuritySlice = createSlice({
    name: 'novaSecurity',
    initialState: {
	changeMarker:    0,
	callSelected:    true,
	putSelected:     false,
	wethSelected:    true,
	wbtcSelected:    false,
	dailySelected:   false,
	weeklySelected:  true,
	monthlySelected: false,
	selectedPeriod:  WEEKLY,
	dailyWethPutSecurity:  null,
	dailyWethCallSecurity: null,
	dailyWbtcPutSecurity:  null,
	dailyWbtcCallSecurity: null,
	weeklyWethPutSecurity:  null,
	weeklyWethCallSecurity: null,
	weeklyWbtcPutSecurity:  null,
	weeklyWbtcCallSecurity: null,
	monthlyWethPutSecurity:  null,
	monthlyWethCallSecurity: null,
	monthlyWbtcPutSecurity:  null,
	monthlyWbtcCallSecurity: null,
	startIntervalIdx: 0,
	openInterestBNs: null,
	totalInterestBNs: null,
	securityName: "WETH",
	security: null,
	gotSecurities: false,
	securityInfoPendingCnt: 0,
	securityInfoIsPending: false,
    },
    reducers: {
	setStartIntervalIdx : (state, action) => {
	    state.startIntervalIdx = action.payload;
	    // changeMarkers only kick when user/external inputs change
	},
	setWethPutSecurity:  (state, action) => {
	    if (action.payload.period == DAILY)
		state.dailyWethPutSecurity = action.payload.security;
	    else if (action.payload.period == WEEKLY)
		state.weeklyWethPutSecurity = action.payload.security;
	    else if (action.payload.period == MONTHLY)
		state.monthlyWethPutSecurity = action.payload.security;
	    ++state.changeMarker;
	},
	setWethCallSecurity: (state, action) => {
	    if (action.payload.period == DAILY)
		state.dailyWethCallSecurity = action.payload.security;
	    else if (action.payload.period == WEEKLY)
		state.weeklyWethCallSecurity = action.payload.security;
	    else if (action.payload.period == MONTHLY)
		state.monthlyWethCallSecurity = action.payload.security;
	},
	setWbtcPutSecurity:  (state, action) => {
	    if (action.payload.period == DAILY)
		state.dailyWbtcPutSecurity = action.payload.security;
	    else if (action.payload.period == WEEKLY)
		state.weeklyWbtcPutSecurity = action.payload.security;
	    else if (action.payload.period == MONTHLY)
		state.monthlyWbtcPutSecurity = action.payload.security;
	    ++state.changeMarker;
	},
	setWbtcCallSecurity: (state, action) => {
	    if (action.payload.period == DAILY)
		state.dailyWbtcCallSecurity = action.payload.security;
	    else if (action.payload.period == WEEKLY)
		state.weeklyWbtcCallSecurity = action.payload.security;
	    else if (action.payload.period == MONTHLY)
		state.monthlyWbtcCallSecurity = action.payload.security;
	    ++state.changeMarker;
	},
	setSecurity: (state, action) => {
	    _setSecurity(state);
	    // the first call to setSecurity is from thunkGetSecurities, after collecting all the different securities.
	    // the initial call to thunkGetSecurities is triggered when gotSecurities is false.
	    state.gotSecurities = true;
	    ++state.changeMarker;
	},
	setDaily: (state, action) => {
	    state.dailySelected = true;
	    state.weeklySelected = false;
	    state.monthlySelected = false;
	    state.selectedPeriod = DAILY;
	    _setSecurity(state);
	    ++state.changeMarker;
	},
	setWeekly: (state, action) => {
	    state.dailySelected = false;
	    state.weeklySelected = true;
	    state.monthlySelected = false;
	    state.selectedPeriod = WEEKLY;
	    _setSecurity(state);
	    ++state.changeMarker;
	},
	setMonthly: (state, action) => {
	    state.dailySelected = false;
	    state.weeklySelected = false;
	    state.monthlySelected = true;
	    state.selectedPeriod = MONTHLY;
	    _setSecurity(state);
	    ++state.changeMarker;
	},
	setWeth: (state, action) => {
	    state.wethSelected = true;
	    state.wbtcSelected = false;
	    state.securityName = "WETH";
	    _setSecurity(state);
	},
	setWbtc: (state, action) => {
	    state.wethSelected = false;
	    state.wbtcSelected = true;
	    state.securityName = "WBTC";
	    _setSecurity(state);
	    ++state.changeMarker;
	},
	setCall: (state, action) => {
	    state.callSelected = true;
	    state.putSelected = false;
	    _setSecurity(state);
	    ++state.changeMarker;
	},
	setPut: (state, action) => {
	    state.callSelected = false;
	    state.putSelected = true;
	    _setSecurity(state);
	    ++state.changeMarker;
	},
	setOpenInterestBNs: (state, action) => {
	    state.openInterestBNs = action.payload.openInterestBNs;
	    state.totalInterestBNs = action.payload.totalInterestBNs;
	    // changeMarkers only kick when user/external inputs change
	},
    },
    extraReducers: {
	// Add reducers for additional action types here, and handle loading state as needed
	// thunks will have X.pending, X.fulfilled, X.rejected
	[thunkGetSecurities.pending]:   (state, action) => {
	    // thunkGetSecurities, upon successful resolution, will always be followed by a call to thunkGetSelectedSecurityInfo
	    // (for the default-selected sucurity). to avoid a flickering wait-spinner we set securityInfoIsPending via
	    // thunkGetSecurities, and we don't clear it untill the resolution of thunkGetSelectedSecurityInfo.
	    state.securityInfoIsPending = true;
	},
	[thunkGetSecurities.fulfilled]: (state, action) => {
	},
	[thunkGetSecurities.rejected]: (state, action) => {
		state.securityInfoIsPending = false;
	},
	[thunkGetSelectedSecurityInfo.pending]:   (state, action) => {
	    ++state.securityInfoPendingCnt;
	    state.securityInfoIsPending = true;
	},
	[thunkGetSelectedSecurityInfo.fulfilled]: (state, action) => {
	    if (--state.securityInfoPendingCnt <= 0) {
		state.securityInfoPendingCnt = 0
		state.securityInfoIsPending = false;
	    }
	},
	[thunkGetSelectedSecurityInfo.rejected]: (state, action) => {
	    if (--state.securityInfoPendingCnt <= 0) {
		state.securityInfoPendingCnt = 0
		state.securityInfoIsPending = false;
	    }
	},
    }
})

export const {
    setDaily, setWeekly, setMonthly,
    setWeth, setWbtc, setCall, setPut,
    setWethPutSecurity,
    setWethCallSecurity,
    setWbtcPutSecurity,
    setWbtcCallSecurity,
    setSecurity,
    setStartIntervalIdx,
    setOpenInterestBNs,
} = novaSecuritySlice.actions;


export const selectNovaSecurity = state => state.novaSecurity;
export default novaSecuritySlice.reducer;
