import React, { useState } from "react";
import { useDispatch, useSelector }     from "react-redux";

import '../css/common.css';
import { selectPage }                   from "../features/pageSlice";
import { selectWallet,
	 thunkApproveDai,
	 thunkApproveWeth,
	 thunkApproveWbtc }                  from "../features/walletSlice";
import { selectNovaSecurity,
	 thunkGetSelectedSecurityInfo } from "../features/novaSecuritySlice";
import { selectNovaContractSelector }   from "../features/novaContractSelectorSlice";
import { selectNovaContractMemo,
	 thunkGetContractMemo }         from "../features/novaContractMemoSlice";
const novaEther = require('../lib/novaEther');
const ether = require('../lib/ether');
const common = require('../lib/common');
const BN = require("bn.js");



let contractSelectorChangeMarkerPrev = -1;
let securityChangeMarkerPrev         = -1;
let walletChangeMarkerPrev           = -1;
let contractMemoChangeMarkerPrev     = -1;

let securityText = '';
let toolTipText = '';
let secBalanceDsp = '0.000';
let selectedDisplay = "Selected contract: No Option Contract Selected";
let daiApprovedMsg = 'Not approved for trading';
let SecurityApprovedMsg = 'Not approved for trading';
let approveDaiButtonText = 'Approve';
let approveSecurityButtonText = 'Approve';
let writtenDsp = '0.000';
let writtenForDsp = '0.000';
let heldDsp = '0.000';
let heldForDsp = '0.000';
let reservedDsp = '0.000';
let reservedForDsp = '0.000';


function SelectedContractWidget(props) {
    const wallet = useSelector(selectWallet);
    const novaSecurity = useSelector(selectNovaSecurity);
    const novaNovaContractSelector = useSelector(selectNovaContractSelector);
    const novaContractMemo = useSelector(selectNovaContractMemo);
    const dispatch = useDispatch();

    const daiBalance = wallet.daiBalance;
    const daiBalanceDsp = common.leftPadTo(parseFloat(daiBalance).toFixed(2), 9, ' ');
    const security = novaSecurity.security;

    const contractsToSecurities = (contractAmount) => {
	const tokensPerContract = ether.convertWeiToDecimal(security.tokensPerContract, 3);
	const securityAmount = !!contractAmount ? common.fixedFloat(common.fixedFloat(contractAmount, 3) * tokensPerContract, 3) : 0;
	return({ contractAmount: contractAmount, securityAmount: securityAmount });
    };

    if (!security || !novaNovaContractSelector.selectedStrikePriceBN) {
	secBalanceDsp             = '0.000';
	selectedDisplay           = "Selected contract: No Option Contract Selected";
	daiApprovedMsg            = 'Not approved for trading';
	SecurityApprovedMsg       = 'Not approved for trading';
	approveDaiButtonText      = 'Approve';
	approveSecurityButtonText = 'Approve';
	writtenDsp = writtenForDsp = heldDsp = heldForDsp = reservedDsp = reservedForDsp = '0.000';
    } else {

	//
	// handle items that change with strike-price / expiration / selected security
	//
	if (contractSelectorChangeMarkerPrev != novaNovaContractSelector.changeMarker   ||
	    securityChangeMarkerPrev         != novaSecurity.changeMarker               ||
	    walletChangeMarkerPrev           != wallet.changeMarker                     ) {

	    dispatch(thunkGetContractMemo({ securityId:     security.id,
					    expirationIdx:  novaNovaContractSelector.selectedExpirationIdx,
					    strikePriceIdx: novaNovaContractSelector.selectedStrikePriceIdx }));

	    // only refresh security info (eg open interest) when security changes
	    if (securityChangeMarkerPrev != novaSecurity.changeMarker) {
		dispatch(thunkGetSelectedSecurityInfo());
	    }

	    contractSelectorChangeMarkerPrev = novaNovaContractSelector.changeMarker
	    securityChangeMarkerPrev         = novaSecurity.changeMarker
	    walletChangeMarkerPrev           = wallet.changeMarker;

	    const intervalBN = security.expirationIntervalBN;
	    const dateBN = security.expirationBaseBN.clone();
	    dateBN.iadd(intervalBN.muln(novaNovaContractSelector.selectedExpirationIdx));
	    const date = new Date();
	    date.setTime(dateBN.muln(1000).toString(10));
	    const dateDisplay = common.days[date.getUTCDay()] + ' ' + common.months[date.getUTCMonth()] + ' ' +
		  common.leftPadTo(date.getUTCDate().toString(),    2, '0') + ' ' + date.getUTCFullYear() + ' ' +
		  common.leftPadTo(date.getUTCHours().toString(),   2, '0') + ":" +
		  common.leftPadTo(date.getUTCMinutes().toString(), 2, '0') + ":" +
		  common.leftPadTo(date.getUTCSeconds().toString(), 2, '0');
	    //Selected contract = Call 100 WETH @ 525 exp: Tue Oct 27 23:59:00 UTC 2020
	    const priceMaybeDecimals = ether.convertWeiToDecimal(novaNovaContractSelector.selectedStrikePriceBN.toString(10), 2);
	    const priceWith2Decimals = parseFloat(priceMaybeDecimals).toFixed(2);
	    const tokensPerContract = parseInt(ether.convertWeiToDecimal(security.tokensPerContractBN.toString(10), 3));
	    selectedDisplay = "Selected contract: " +
		(novaSecurity.callSelected ? "Call" : "Put") + " " +
		tokensPerContract + " " +
		(novaSecurity.wethSelected ? "WETH"  : "WBTC") + " " +
		"@ " + priceWith2Decimals + " " +
		"exp: " + dateDisplay + " UTC";
	    const secBalance = novaSecurity.wethSelected ? wallet.wethBalance : wallet.wbtcBalance;
	    secBalanceDsp = common.leftPadTo(parseFloat(secBalance).toFixed(3), 9, ' ');
	    securityText = novaSecurity.wethSelected ? 'WETH' : 'WBTC';
	}

	//
	// handle items that change with contractMemo
	//
//	if (contractMemoChangeMarkerPrev != novaContractMemo.changeMarker) {
//	    contractMemoChangeMarkerPrev = novaContractMemo.changeMarker;
	    console.log('SelectedContractWidget: REDRAWING, novaContractMemo.ownedInfo = ' + JSON.stringify(novaContractMemo.ownedInfo));
	    if (!novaContractMemo.ownedInfo) {
		heldDsp = writtenDsp = reservedDsp = heldForDsp = writtenForDsp = reservedForDsp = '0.000';
	    } else {
		const totalWrittenBN    = novaContractMemo.ownedInfo.amountWrittenBN.add(novaContractMemo.ownedInfo.amountFreeBN);
		const writtenAmountRaw  = ether.convertWeiToDecimal(totalWrittenBN.toString(10), 3);
		const writtenAmounts    = contractsToSecurities(writtenAmountRaw);
		// "held" amount is really owned amount
		const heldAmountRaw     = ether.convertWeiToDecimal(novaContractMemo.ownedInfo.amountOwnedBN.toString(10), 3);
		const heldAmounts       = contractsToSecurities(heldAmountRaw);
		const reservedAmountRaw = ether.convertWeiToDecimal(novaContractMemo.ownedInfo.amountReservedBN.toString(10), 3);
		const reservedAmounts   = contractsToSecurities(reservedAmountRaw);
		writtenDsp     = parseFloat(writtenAmounts.contractAmount ).toFixed(3);
		writtenForDsp  = parseFloat(writtenAmounts.securityAmount ).toFixed(3);
		heldDsp        = parseFloat(heldAmounts.contractAmount    ).toFixed(3);
		heldForDsp     = parseFloat(heldAmounts.securityAmount    ).toFixed(3);
		reservedDsp    = parseFloat(reservedAmounts.contractAmount).toFixed(3);
		reservedForDsp = parseFloat(reservedAmounts.securityAmount).toFixed(3);
	    }
//	}
    }


    const beenRendered = !!document.getElementById('selectedContractWidgetDiv');
    if (!!beenRendered) {
	if (!!wallet.walletInfoIsPending)
	    common.replaceElemClassFromTo("walletWaitIcon", "hidden", "visible");
	else
	    common.replaceElemClassFromTo("walletWaitIcon", "visible", "hidden");
	if (wallet.connected) {
	    if (wallet.haveDaiApproval) {
		daiApprovedMsg = 'Approved for trading';
		approveDaiButtonText = 'Revoke';
	    } else {
		daiApprovedMsg = 'Not approved for trading';
		approveDaiButtonText = 'Approve';
	    }
	    if ((novaSecurity.wethSelected && wallet.haveWethApproval) ||
		(novaSecurity.wbtcSelected && wallet.haveWbtcApproval) ) {
		SecurityApprovedMsg = 'Approved for trading';
		approveSecurityButtonText = 'Revoke';
	    } else {
		SecurityApprovedMsg = 'Not approved for trading';
		approveSecurityButtonText = 'Approve';
	    }
	    common.setButtonState("approveDaiButton",      "Enabled");
	    common.setButtonState("approveSecurityButton", "Enabled");
	} else {
	    common.setButtonState("approveDaiButton",      "Disabled");
	    common.setButtonState("approveSecurityButton", "Disabled");
	}
    }

    const onApproveDai = () => {
	if (!!wallet.connected) {
	    const doSet = !wallet.haveDaiApproval;
	    dispatch(thunkApproveDai({ doSet: doSet }));
	}
    };

    const onApproveSecurity = () => {
	if (!!wallet.connected) {
	    if (novaSecurity.wethSelected) {
		const doSet = !wallet.haveWethApproval;
		dispatch(thunkApproveWeth({ doSet: doSet }));
	    } else {
		const doSet = !wallet.haveWbtcApproval;
		dispatch(thunkApproveWbtc({ doSet: doSet }));
	    }
	}
    };

    return (
            <div id="selectedContractWidgetDiv" className="plank panel">
            <div id="walletWaitIcon" className="hidden"/>
	    <div className="panelTitle">
	      {selectedDisplay}
	    </div>
	    <div id="heldWrittenOfferedDiv" className="panelFatSubtitle">
	      <div>Held: {heldDsp} ({heldForDsp} {securityText})</div>
	      <div>Written: {writtenDsp} ({writtenForDsp} {securityText})</div>
	      <div>Offered: {reservedDsp} ({reservedForDsp} {securityText})</div>
	    </div>
	    <div id="balancesDiv" className="panelFatSubtitle">
	      <div>Balance: {daiBalanceDsp} DAI</div>
	      <div>{daiApprovedMsg}</div>
	      <div><button id="approveDaiButton" type="button" className="button" onClick={onApproveDai}>{approveDaiButtonText}</button></div>
	      <div>Balance: {secBalanceDsp} {securityText}</div>
	      <div>{SecurityApprovedMsg}</div>
	      <div> <button id="approveSecurityButton" type="button" className="button" onClick={onApproveSecurity}>{approveSecurityButtonText}</button></div>
	    </div>
	  </div>
	  );
}

export default SelectedContractWidget;
