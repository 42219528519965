import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import '../css/common.css';
import { selectPage } from "../features/pageSlice";
const common = require('../lib/common');


const TradeHistory = () => {
    const page = useSelector(selectPage);
    //const dispatch = useDispatch();

    const beenRendered = !!document.getElementById('tradeHistoryPanel');
    if (beenRendered) {
	if (page.pageMode == 'trade')
	    common.replaceElemClassFromTo("tradeHistoryPanel", "hidden", "visible");
	else
	    common.replaceElemClassFromTo("tradeHistoryPanel", "visible", "hidden");
    }

    const asks = [];
    const bids = [];
    for (let i = 0; i < 5; ++i)
	asks.push(<p class="orderBookLine">0.500 contracts (50 WETH) 100 DAI/contract</p>);
    for (let i = 0; i < 5; ++i)
	bids.push(<p class="orderBookLine">0.500 contracts (50 WETH) 100 DAI/contract</p>);
    return (
	    <div id="tradeHistoryPanel" class="panel hidden">
	      <div class="panelTitle">
		Trade History
	      </div>
	      <div class="panelSubtitle">
		Call 100 WETH @ 525 exp: 20200927:235900
              </div>
 	      <p class="orderTypeLine">&nbsp;</p>
              {asks}
              {bids}
	    </div>
	  );
}

export default TradeHistory;
