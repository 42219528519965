import React, { useState } from "react";
import { useDispatch, useSelector }   from "react-redux";

import '../css/common.css';
import { selectPage }                 from "../features/pageSlice";
import { selectWallet }               from "../features/walletSlice";
import { selectNovaSecurity }         from "../features/novaSecuritySlice";
import { selectNovaContractSelector,
	 selectedDsp }                from "../features/novaContractSelectorSlice";
import { selectNovaContractMemo,
	 canExercise,
	 canCancel }                  from "../features/novaContractMemoSlice";
import { selectNovaOptionEvents,
	 thunkGetMyOptions,
	 setExpandOptionList,
	 setOptionListType,
	 thunkCancelOption }          from "../features/novaOptionEventsSlice";
const novaEther = require('../lib/novaEther');
const ether = require('../lib/ether');
const common = require('../lib/common');
const BN = require("bn.js");

let walletChangeMarkerPrev        = -1;
let contractMemoChangeMarkerPrev  = -1;
let doExpand = false;

const dateDsp = (date) => {
    const dateDisplay = common.days[date.getUTCDay()] + ' ' + common.months[date.getUTCMonth()] + ' ' +
	  common.leftPadTo(date.getUTCDate().toString(),    2, '0') + ' ' + date.getUTCFullYear() + ' ' +
	  common.leftPadTo(date.getUTCHours().toString(),   2, '0') + ":" +
	  common.leftPadTo(date.getUTCMinutes().toString(), 2, '0') + ":" +
	  common.leftPadTo(date.getUTCSeconds().toString(), 2, '0') + " UTC";
    return(dateDisplay);
};


const OptionsListWidget = () => {
    const page = useSelector(selectPage);
    const wallet = useSelector(selectWallet);
    const novaSecurity = useSelector(selectNovaSecurity);
    const novaContractSelector = useSelector(selectNovaContractSelector);
    const novaContractMemo = useSelector(selectNovaContractMemo);
    const novaOptionEvents = useSelector(selectNovaOptionEvents);
    const dispatch = useDispatch();

    let securityText = 'WETH';
    let selectedDisplay = "Selected contract: No Option Contract Selected";


    const onCollapse = () => {
	dispatch(setExpandOptionList(false));
    };
    const onExpand = () => {
	dispatch(setExpandOptionList(true));
    };
    const onShowBuys = () => {
	dispatch(setOptionListType('buys'));
    };
    const onShowSells = () => {
	dispatch(setOptionListType('sells'));
    };
    const onShowBuysAndSells = () => {
	dispatch(setOptionListType('all'));
    };
    const onDelete = (optionBN) => {
	console.log("got delete: optionBN = " + optionBN.toString(10));
	//dispatch(thunkCancelOption(optionBN));
    };


    const BNToDsp = (xBN, decimals) => {
	const valueMaybeDecimals = ether.convertWeiToDecimal(xBN.toString(10), decimals);
	const valueWithDecimals = parseFloat(valueMaybeDecimals).toFixed(decimals);
	return(valueWithDecimals);
    }


    if (page.pageMode == 'write' &&
	(contractMemoChangeMarkerPrev != novaContractMemo.changeMarker ||
	 walletChangeMarkerPrev       != wallet.changeMarker            )) {
	walletChangeMarkerPrev        = wallet.changeMarker;
	contractMemoChangeMarkerPrev  = novaContractMemo.changeMarker;
	if (!!novaContractMemo.contractMemo && !!wallet.connected) {
	    dispatch(thunkGetMyOptions({ }));
	}
    }

    const options = [];
    const beenRendered = !!document.getElementById('optionsListWidgetDiv');
    if (beenRendered) {
	if (page.pageMode != 'write') {
	    common.replaceElemClassFromTo("optionsListWidgetDiv", "visible", "hidden");
	} else {
	    common.replaceElemClassFromTo("optionsListWidgetDiv", "hidden", "visible");
	    if (!!novaOptionEvents.optionEventsIsPending)
		common.replaceElemClassFromTo("optionsListWaitIcon", "hidden", "visible");
	    else
		common.replaceElemClassFromTo("optionsListWaitIcon", "visible", "hidden");
	    if (!novaContractMemo.contractMemo) {
		;
	    } else {
		console.log('OptionsListWidget: REDRAWING');
		const security = novaSecurity.security;
		selectedDisplay = selectedDsp(novaSecurity, novaContractSelector);
		const optionEvents = novaOptionEvents.optionEvents;
		const totalWrittenBN = new BN("0");
		const totalHeldBN    = new BN("0");
		const totalOfferedBN = new BN("0");
		const totalSoldBN    = new BN("0");
		for (let i = 0; i < optionEvents.length; ++i) {
		    const optionEvent = optionEvents[i];
		    const eventDateDsp = dateDsp(optionEvent.date);
		    const action =
			  optionEvent.whatEventBN.eqn(novaEther.OPTION_WRITTEN_EVENT)         ? "written"      :
			  optionEvent.whatEventBN.eqn(novaEther.OPTION_OFFER_EVENT)           ? "offered"      :
			  optionEvent.whatEventBN.eqn(novaEther.OPTION_OFFER_CANCEL_EVENT)    ? "cancel offer" :
			  optionEvent.whatEventBN.eqn(novaEther.OPTION_XFER_FROM_EVENT)       ? "sold"         :
			  optionEvent.whatEventBN.eqn(novaEther.OPTION_XFER_TO_EVENT)         ? "purchase"     :
			  optionEvent.whatEventBN.eqn(novaEther.OPTION_HOLDER_EXERCISE_EVENT) ? "exercised"    :
			  optionEvent.whatEventBN.eqn(novaEther.OPTION_WRITER_EXERCISE_EVENT) ? "exercise"     :
			  optionEvent.whatEventBN.eqn(novaEther.OPTION_CANCELED_EVENT)        ? "canceled"     :
			  optionEvent.whatEventBN.eqn(novaEther.OPTION_EXPIRED_EVENT)         ? "expired"      : "unknown event";
		    const amountDsp = BNToDsp(optionEvent.amountBN, 3);
		    const proceedsSumBN = new BN("0");
		    if (optionEvent.whatEventBN.eqn(novaEther.OPTION_WRITTEN_EVENT)) {
			totalHeldBN.iadd(optionEvent.amountBN);
			totalWrittenBN.iadd(optionEvent.amountBN);
		    } else if (optionEvent.whatEventBN.eqn(novaEther.OPTION_OFFER_EVENT)) {
			totalOfferedBN.iadd(optionEvent.amountBN);
		    } else if (optionEvent.whatEventBN.eqn(novaEther.OPTION_OFFER_CANCEL_EVENT)) {
			totalOfferedBN.isub(optionEvent.amountBN);
		    } else if (optionEvent.whatEventBN.eqn(novaEther.OPTION_XFER_FROM_EVENT)) {
			totalHeldBN.isub(optionEvent.amountBN);
			totalOfferedBN.isub(optionEvent.amountBN);
			totalSoldBN.iadd(optionEvent.amountBN);
		    } else if (optionEvent.whatEventBN.eqn(novaEther.OPTION_XFER_TO_EVENT)) {
			totalHeldBN.iadd(optionEvent.amountBN);
		    } else if (optionEvent.whatEventBN.eqn(novaEther.OPTION_HOLDER_EXERCISE_EVENT)) {
			totalHeldBN.isub(optionEvent.amountBN);
		    } else if (optionEvent.whatEventBN.eqn(novaEther.OPTION_WRITER_EXERCISE_EVENT)) {
			totalWrittenBN.isub(optionEvent.amountBN);
		    }
		    const writtenDsp = BNToDsp(totalWrittenBN, 3);
		    const heldDsp    = BNToDsp(totalHeldBN, 3);
		    const offeredDsp = BNToDsp(totalOfferedBN, 3);
		    const soldDsp    = BNToDsp(totalSoldBN, 3);
		    const cancelable =  (i == optionEvents.length - 1) && canCancel(novaContractMemo);
		    const exercisable = (i == optionEvents.length - 1) && canExercise(novaContractMemo);
		    //!totalWrittenBN.isZero() && totalHeldBN.gt(totalOfferedBN);
		    let cancelAction = null;
		    let exerciseAction = null;
		    options.push({
			date:           eventDateDsp,
			action:         action,
			actionClass:    "",
			amount:         amountDsp,
			written:        writtenDsp,
			held:           heldDsp,
			offered:        offeredDsp,
			sold:           soldDsp,
			exerciseClass:  exercisable ? "optionsListTableExercise" : "optionsListTableStatus",
			exerciseAction: exercisable ? exerciseAction : null,
			cancelClass:    cancelable  ? "optionsListTableCancel"   : "optionsListTableStatus",
			cancelAction:   cancelable  ? cancelAction : null,
		    });
		}
	    }
	}
    }
    return (
	    <div id="optionsListWidgetDiv" class="plank panel hidden">
            <div id="optionsListWaitIcon" className="hidden"/>
	      <div class="panelTitle">
		My Contracts
	      </div>
	      <div class="panelSubtitle">
		{selectedDisplay}
	      </div>
	      <div id="optionListButtonsDiv">
		<button id="optionListShowAllButton"          type="button" className="button" onClick={onShowBuysAndSells}>Show all</button>
		<button id="optionListShowBuysButton"         type="button" className="button" onClick={onShowBuys}>Show bids</button>
		<button id="optionListShowSellsButton"        type="button" className="button" onClick={onShowSells}>Show offers</button>
		<button id="optionListExpandAllButton"        type="button" className="button" onClick={onExpand}>Expand</button>
		<button id="optionListCollapseAllButton"      type="button" className="button" onClick={onCollapse}>Collapse</button>
	      </div>
	      <table class="optionsListTable">
		<tr>
		  <th></th>
		  <th></th>
		  <th></th>
		  <th>total</th>
		  <th>total</th>
	          <th>total</th>
	          <th>total</th>
		  <th></th>
		  <th></th>
	        </tr>
		<tr>
		  <th id="optionsListTableDateHdr">date</th>
		  <th id="optionsListTableEventHdr">event</th>
		  <th id="optionsListTableAmountHdr">amount</th>
		  <th id="optionsListTableWrittenHdr">written</th>
		  <th id="optionsListTableHeldHdr">held</th>
	          <th id="optionsListTableOfferedHdr">offered</th>
		  <th>sold</th>
		  <th></th>
		  <th></th>
	        </tr>
		{options.map(option => (
			<tr>
			  <td>{option.date}</td>
			  <td className={option.actionClass}>{option.action}</td>
			  <td>{option.amount}</td>
			  <td>{option.written}</td>
			  <td>{option.held}</td>
			  <td>{option.offered}</td>
			  <td>{option.sold}</td>
			  <td className={option.exerciseClass} onClick={option.exerciseAction}></td>
			  <td className={option.cancelClass}   onClick={option.cancelAction}></td>
			</tr>
			))}
	      </table>
	   </div>
	  );
}

export default OptionsListWidget;
