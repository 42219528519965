import React, { useState } from "react";
import { useDispatch, useSelector }   from "react-redux";

import '../css/common.css';
import { selectPage }                 from "../features/pageSlice";
import { selectWallet }               from "../features/walletSlice";
import { selectNovaSecurity }         from "../features/novaSecuritySlice";
import { selectNovaContractSelector,
	 selectedDsp }                from "../features/novaContractSelectorSlice";
import { selectNovaContractMemo }     from "../features/novaContractMemoSlice";
import { selectNovaPlaceOrder,
	 thunkCalcOrderDepositAmount,
	 thunkPlaceOrder,
	 setOrderIsBuy,
	 setOrderPrice,
	 setOrderAmount }             from "../features/novaPlaceOrderSlice";

const novaEther = require('../lib/novaEther');
const ether = require('../lib/ether');
const common = require('../lib/common');
const BN = require("bn.js");

let prevSelectedDisplay = "";

const PlaceOrder = () => {
    const page = useSelector(selectPage);
    const wallet = useSelector(selectWallet);
    const novaSecurity = useSelector(selectNovaSecurity);
    const novaContractSelector = useSelector(selectNovaContractSelector);
    const novaContractMemo = useSelector(selectNovaContractMemo);
    const novaPlaceOrder = useSelector(selectNovaPlaceOrder);
    const dispatch = useDispatch();

    let securityText = 'WETH';
    let toolTipText = '';
    let priceDisplay = '';
    let contractAmountDisplay = '';
    let contractAmountDisplayAlt = '';
    let depositMsg0 = '';
    let depositMsg1 = '';
    let depositMsg2 = '';
    let depositMsg3 = '';
    let selectedDisplay = "Selected contract: No Option Contract Selected";
    let youMustMsg = "You need to connect your wallet";
    const security = novaSecurity.security;


    const contractsToSecurities = (contractAmount) => {
	const tokensPerContract = ether.convertWeiToDecimal(security.tokensPerContract, 3);
	const securityAmount = !!contractAmount ? common.fixedFloat(common.fixedFloat(contractAmount, 3) * tokensPerContract, 3) : 0;
	return({ contractAmount: contractAmount, securityAmount: securityAmount });
    };
    const securitiesToContracts = (securityAmount) => {
	const tokensPerContract = ether.convertWeiToDecimal(security.tokensPerContract, 3);
	const contractAmount = !!securityAmount ? common.fixedFloat(common.fixedFloat(securityAmount, 3) / tokensPerContract, 3) : 0;
	return({ contractAmount: contractAmount, securityAmount: securityAmount });
    };

    const onBuySelect = () => {
	dispatch(setOrderIsBuy(true));
	dispatch(thunkCalcOrderDepositAmount({ }));
    };
    const onSellSelect = () => {
	dispatch(setOrderIsBuy(false));
	dispatch(thunkCalcOrderDepositAmount({ }));
    };
    const onPriceInput = () => {
	const price = document.getElementById('placeOrderPriceEntry').value;
	dispatch(setOrderPrice(price));
	dispatch(thunkCalcOrderDepositAmount({ }));
    };
    const onPriceBlur = () => {
	const price = common.fixedFloat(document.getElementById('placeOrderPriceEntry').value, 2);
	dispatch(setOrderPrice(price));
	dispatch(thunkCalcOrderDepositAmount({ }));
    };
    const onContractAmountInput = () => {
	const contractAmount = document.getElementById('placeOrderAmountEntry').value;
	const amounts = contractsToSecurities(contractAmount);
	dispatch(setOrderAmount(amounts));
	dispatch(thunkCalcOrderDepositAmount({ }));
    };
    const onContractAmountBlur = () => {
	const contractAmount = common.fixedFloat(document.getElementById('placeOrderAmountEntry').value, 3);
	const amounts = contractsToSecurities(contractAmount);
	dispatch(setOrderAmount(amounts));
	dispatch(thunkCalcOrderDepositAmount({ }));
    };
    const onContractAmountAltInput = () => {
	const securityAmount = document.getElementById('placeOrderAmountEntryAlt').value;
	const amounts = securitiesToContracts(securityAmount);
	dispatch(setOrderAmount(amounts));
	dispatch(thunkCalcOrderDepositAmount({ }));
    };
    const onContractAmountAltBlur = () => {
	const securityAmount = common.fixedFloat(document.getElementById('placeOrderAmountEntryAlt').value, 3);
	const rawAmounts = securitiesToContracts(securityAmount);
	const amounts = contractsToSecurities(rawAmounts.contractAmount);
	dispatch(setOrderAmount(amounts));
	dispatch(thunkCalcOrderDepositAmount({ }));
    };
    const onOrderExecute = () => {
	console.log('onOrderExecute');
	dispatch(thunkPlaceOrder({ }));
    };

    const disableExecuteButton = () => {
	common.setButtonState("placeOrderExecuteButton", "Disabled");
	common.replaceElemClassFromTo("placeOrderExecuteButton", "sellBackgroundColor", "unsetBackgroundColor");
	common.replaceElemClassFromTo("placeOrderExecuteButton", "buyBackgroundColor", "unsetBackgroundColor");
    };


    const beenRendered = !!document.getElementById('placeOrderPanel');
    if (beenRendered) {
	if (page.pageMode != 'trade') {
	    common.replaceElemClassFromTo("placeOrderPanel", "visible", "hidden")
	} else {
	    common.replaceElemClassFromTo("placeOrderPanel", "hidden", "visible");
	    if (!!novaPlaceOrder.placeOrderIsPending)
		common.replaceElemClassFromTo("placeOrderWaitIcon", "hidden", "visible");
	    else
		common.replaceElemClassFromTo("placeOrderWaitIcon", "visible", "hidden");
	    if (!security || !novaContractSelector.selectedStrikePriceBN) {
		youMustMsg = "You need to select a contract";
		common.setButtonState("placeOrderBuyButton",  "Disabled");
		common.setButtonState("placeOrderSellButton", "Disabled");
		common.setButtonState("writeContractExecuteButton", "Disabled");
		common.replaceElemClassFromTo("writeContractYouMustMsgDiv", "hidden", "visible");
	    } else {
		selectedDisplay = selectedDsp(novaSecurity, novaContractSelector);
		priceDisplay = novaPlaceOrder.orderPrice.toString();
		contractAmountDisplay = novaPlaceOrder.orderContractAmount.toString();
		contractAmountDisplayAlt = novaPlaceOrder.orderSecurityAmount.toString();
		const daiDepositAmountWeiBN = novaPlaceOrder.orderDaiNominalAmountWeiBN ?
		      novaPlaceOrder.orderDaiNominalAmountWeiBN.add(novaPlaceOrder.orderDaiFeeAmountWeiBN) : new BN("0");
		console.log('PlaceOrder: REDRAWING');
		if (novaPlaceOrder.orderIsBuy) {
		    common.setButtonState("placeOrderBuyButton",  "Selected");
		    common.setButtonState("placeOrderSellButton", "Enabled");
		    depositMsg0 = 'bid will deposit ' + parseFloat(novaPlaceOrder.orderDaiNominalAmount).toFixed(2) + ' DAI';
		    depositMsg1 = 'plus fees up to ' + parseFloat(novaPlaceOrder.orderDaiFeeAmount).toFixed(2) + ' DAI';
		    depositMsg2 = '(maker 0.25%, taker 0.35%)';
		    depositMsg3 = 'to purchase ' + parseFloat(contractAmountDisplay).toFixed(3) + ' contracts';
		} else {
		    common.setButtonState("placeOrderBuyButton",  "Enabled");
		    common.setButtonState("placeOrderSellButton", "Selected");
		    depositMsg0 = 'offer will reserve ' + parseFloat(contractAmountDisplay).toFixed(3) + ' contracts';
		    depositMsg1 = 'for nominal proceeds of ' + parseFloat(novaPlaceOrder.orderDaiNominalAmount).toFixed(2) + ' DAI';
		    depositMsg2 = 'minus fees, up to ' + parseFloat(novaPlaceOrder.orderDaiFeeAmount).toFixed(2) + ' DAI';
		    depositMsg3 = '(maker 0.25%, taker 0.35%)';
		}
		if (prevSelectedDisplay.indexOf(selectedDisplay) != 0) {
		    prevSelectedDisplay = selectedDisplay;
		    onContractAmountBlur();
		}
		if (!wallet.connected) {
		    console.log('PlaceOrder: A');
		    youMustMsg = "You need to connect your wallet";
		    common.replaceElemClassFromTo("placeOrderYouMustMsgDiv", "hidden", "visible");
		    disableExecuteButton();
		} else if (novaPlaceOrder.orderIsBuy && !wallet.haveDaiApproval) {
		    console.log('PlaceOrder: B');
		    youMustMsg = "You need to approve DAI for trading";
		    common.replaceElemClassFromTo("placeOrderYouMustMsgDiv", "hidden", "visible");
		    disableExecuteButton();
		} else if (novaPlaceOrder.orderIsBuy &&
			   (!wallet.daiBalanceWeiBN ||
			    wallet.daiBalanceWeiBN.lt(daiDepositAmountWeiBN))) {
		    console.log('PlaceOrder: C');
		    youMustMsg = "You don\'t have enough DAI for this order";
		    common.replaceElemClassFromTo("placeOrderYouMustMsgDiv", "hidden", "visible");
		    disableExecuteButton();
		} else if ((!novaPlaceOrder.orderIsBuy && !!novaPlaceOrder.orderContractAmountWeiBN                ) &&
			   (!novaContractMemo.ownedInfo ||
			    novaContractMemo.ownedInfo.amountSellableBN.lt(novaPlaceOrder.orderContractAmountWeiBN))) {
		    console.log('PlaceOrder: D');
		    youMustMsg = "You don\'t own enough contracts for this order";
		    common.replaceElemClassFromTo("placeOrderYouMustMsgDiv", "hidden", "visible");
		    disableExecuteButton();
		} else if ((novaPlaceOrder.orderIsBuy &&
			    (!daiDepositAmountWeiBN                  || daiDepositAmountWeiBN.isZero())                    ) ||
			   (!novaPlaceOrder.orderPriceWeiBN          || novaPlaceOrder.orderPriceWeiBN.isZero()         ||
			    !novaPlaceOrder.orderContractAmountWeiBN || novaPlaceOrder.orderContractAmountWeiBN.isZero()    )  ) {
		    console.log('PlaceOrder: E');
		    common.replaceElemClassFromTo("placeOrderYouMustMsgDiv", "visible", "hidden");
		    disableExecuteButton();
		} else {
		    console.log('PlaceOrder: F');
		    common.setButtonState("placeOrderExecuteButton",  "Enabled");
		    if (novaPlaceOrder.orderIsBuy) {
			common.replaceElemClassFromTo("placeOrderExecuteButton", "sellBackgroundColor", "buyBackgroundColor");
			common.replaceElemClassFromTo("placeOrderExecuteButton", "unsetBackgroundColor", "buyBackgroundColor");
		    } else {
			common.replaceElemClassFromTo("placeOrderExecuteButton", "buyBackgroundColor", "sellBackgroundColor");
			common.replaceElemClassFromTo("placeOrderExecuteButton", "unsetBackgroundColor", "sellBackgroundColor");
		    }
		    common.replaceElemClassFromTo("placeOrderYouMustMsgDiv", "visible", "hidden");

		}
	    }
	}
    }

    return (
            <div id="placeOrderPanel" class="panel hidden">
            <div id="placeOrderWaitIcon" className="hidden"/>
	      <div class="panelTitle">
		Place Order
	      </div>
	      <div className="panelSubtitle">
		{selectedDisplay}
	      </div>
	      <div id="placeOrderBuySellButtonsDiv">
		<button id="placeOrderBuyButton" type="button" className="button" onClick={onBuySelect}>Buy</button>
		<button id="placeOrderSellButton" type="button" className="button" onClick={onSellSelect}>Sell</button>
	      </div>
	      <div id="placeOrderSubPanel">
		<div id="placeOrderAmountQuadLine">
		  <label id="placeOrderPriceLabel" for="price-entry">Price</label>
		  <input id="placeOrderPriceEntry" name="price-entry" placeholder="0" value={priceDisplay} onInput={onPriceInput} onBlur={onPriceBlur} />
		  <p id="placeOrderPriceDenom">DAI / Contract</p>
		  <label id="placeOrderAmountLabel" for="contract-amount-entry">Amount</label>
		  <input id="placeOrderAmountEntry" placeholder="0" value={contractAmountDisplay} onInput={onContractAmountInput} onBlur={onContractAmountBlur}/>
		  <p id="placeOrderAmountDenom">Contracts</p>
		  <label id="placeOrderAmountLabelAlt" for="security-amount-entry">For</label>
	          <input id="placeOrderAmountEntryAlt" placeholder="0" value={contractAmountDisplayAlt} onInput={onContractAmountAltInput} onBlur={onContractAmountAltBlur}/>
		  <p id="placeOrderAmountDenomAlt">{securityText}</p>
		  <div id="placeOrderDepositMsgDiv">
		    {depositMsg0}<br/>
		    {depositMsg1}<br/>
		    {depositMsg2}<br/>
		    {depositMsg3}
		  </div>
		</div>
		<div id="placeOrderSubPanelBottomDiv">
	    	  <div id="placeOrderYouMustMsgDiv" className="visible">
		    {youMustMsg}
		  </div>
		  <button id="placeOrderExecuteButton" type="button" className="button unsetBackgroundColor" onClick={onOrderExecute}>Place Order</button>
		</div>
	      </div>
	    </div>
	);
}

export default PlaceOrder;
