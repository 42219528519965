import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import '../css/common.css';
import fakePriceChart from "../img/fakePriceChart.png"
import { selectPage } from "../features/pageSlice";
const common = require('../lib/common');

const PriceChart = () => {
    const page = useSelector(selectPage);

    const beenRendered = !!document.getElementById('priceChartPanel');
    if (beenRendered) {
	if (page.pageMode == 'trade')
	    common.replaceElemClassFromTo("priceChartPanel", "hidden", "visible");
	else
	    common.replaceElemClassFromTo("priceChartPanel", "visible", "hidden")
    }

    return (
            <div id="priceChartPanel" class="panel hidden">
	      <div class="panelTitle">
		Price Chart
	      </div>
	      <div class="panelSubtitle">
		Call 100 WETH @ 525 exp: 20200930:235900
	      </div>
              <p class="orderTypeLine">&nbsp;</p>
              <img src={fakePriceChart} className="priceChartImg" alt="price chart" />
	    </div>
	  );

}

export default PriceChart;
