import React, { useState } from "react";
import { useDispatch, useSelector }   from "react-redux";

import '../css/common.css';
import { selectPage }                 from "../features/pageSlice";
import { selectWallet }               from "../features/walletSlice";
import { selectNovaSecurity }         from "../features/novaSecuritySlice";
import { selectNovaContractSelector,
	 selectedDsp }                from "../features/novaContractSelectorSlice";
import { selectNovaContractMemo }     from "../features/novaContractMemoSlice";
import { selectNovaUserOrders,
	 thunkGetMyOrders,
	 setExpandOrderList,
	 setOrderListType,
	 thunkCancelOrder }           from "../features/novaUserOrdersSlice";
const novaEther = require('../lib/novaEther');
const ether = require('../lib/ether');
const common = require('../lib/common');
const BN = require("bn.js");

let walletChangeMarkerPrev        = -1;
let contractMemoChangeMarkerPrev  = -1;
let doExpand = false;
let selectedDisplay = "Selected contract: No Option Contract Selected";

const dateDsp = (date) => {
    const dateDisplay = common.days[date.getUTCDay()] + ' ' + common.months[date.getUTCMonth()] + ' ' +
	  common.leftPadTo(date.getUTCDate().toString(),    2, '0') + ' ' + date.getUTCFullYear() + ' ' +
	  common.leftPadTo(date.getUTCHours().toString(),   2, '0') + ":" +
	  common.leftPadTo(date.getUTCMinutes().toString(), 2, '0') + ":" +
	  common.leftPadTo(date.getUTCSeconds().toString(), 2, '0') + " UTC";
    return(dateDisplay);
};


const OrdersListWidget = () => {
    const page = useSelector(selectPage);
    const wallet = useSelector(selectWallet);
    const novaSecurity = useSelector(selectNovaSecurity);
    const novaContractSelector = useSelector(selectNovaContractSelector);
    const novaContractMemo = useSelector(selectNovaContractMemo);
    const novaUserOrders = useSelector(selectNovaUserOrders);
    const dispatch = useDispatch();

    let securityText = 'WETH';


    const onCollapse = () => {
	dispatch(setExpandOrderList(false));
    };
    const onExpand = () => {
	dispatch(setExpandOrderList(true));
    };
    const onShowBuys = () => {
	dispatch(setOrderListType('buys'));
    };
    const onShowSells = () => {
	dispatch(setOrderListType('sells'));
    };
    const onShowBuysAndSells = () => {
	dispatch(setOrderListType('all'));
    };
    const onDelete = (orderBN) => {
	console.log("got delete: orderBN = " + orderBN.toString(10));
	dispatch(thunkCancelOrder(orderBN));
    };

    const BNToDsp = (xBN, decimals) => {
	const valueMaybeDecimals = ether.convertWeiToDecimal(xBN.toString(10), decimals);
	const valueWithDecimals = parseFloat(valueMaybeDecimals).toFixed(decimals);
	return(valueWithDecimals);
    }


    if (page.pageMode == 'trade' &&
	(contractMemoChangeMarkerPrev != novaContractMemo.changeMarker ||
	 walletChangeMarkerPrev       != wallet.changeMarker            )) {
	walletChangeMarkerPrev        = wallet.changeMarker;
	contractMemoChangeMarkerPrev  = novaContractMemo.changeMarker;
	if (!!novaContractMemo.contractMemo && !!wallet.connected) {
	    dispatch(thunkGetMyOrders({ }));
	}
    }

    const orders = [];
    const beenRendered = !!document.getElementById('ordersListWidgetDiv');
    if (beenRendered) {
	if (page.pageMode != 'trade') {
	    common.replaceElemClassFromTo("ordersListWidgetDiv", "visible", "hidden");
	} else {
	    common.replaceElemClassFromTo("ordersListWidgetDiv", "hidden", "visible");
	    if (!!novaUserOrders.userOrdersIsPending)
		common.replaceElemClassFromTo("ordersListWaitIcon", "hidden", "visible");
	    else
		common.replaceElemClassFromTo("ordersListWaitIcon", "visible", "hidden");
	    selectedDisplay = selectedDsp(novaSecurity, novaContractSelector);
	    if (!novaContractMemo.contractMemo) {
		common.setButtonState("orderListExpandAllButton",        "Disabled");
		common.setButtonState("orderListCollapseAllButton",      "Disabled");
		common.setButtonState("orderListShowBuysAndSellsButton", "Disabled");
		common.setButtonState("orderListShowBuysButton",         "Disabled");
		common.setButtonState("orderListShowSellsButton",        "Disabled");
	    } else {
		console.log('OrdersListWidget: REDRAWING');
		const security = novaSecurity.security;
		const userOrders = novaUserOrders.userOrders;
		if (userOrders.length == 0) {
		    common.setButtonState("orderListExpandAllButton",        "Disabled");
		    common.setButtonState("orderListCollapseAllButton",      "Disabled");
		    common.setButtonState("orderListShowBuysAndSellsButton", "Disabled");
		    common.setButtonState("orderListShowBuysButton",         "Disabled");
		    common.setButtonState("orderListShowSellsButton",        "Disabled");
		} else {
		    if (novaUserOrders.orderListType == 'all') {
			common.setButtonState("orderListShowBuysAndSellsButton", "Selected");
			common.setButtonState("orderListShowBuysButton",         "Enabled");
			common.setButtonState("orderListShowSellsButton",        "Enabled");
		    } else if (novaUserOrders.orderListType == 'buys') {
			common.setButtonState("orderListShowBuysAndSellsButton", "Enabled");
			common.setButtonState("orderListShowBuysButton",         "Selected");
			common.setButtonState("orderListShowSellsButton",        "Enabled");
		    } else {
			common.setButtonState("orderListShowBuysAndSellsButton", "Enabled");
			common.setButtonState("orderListShowBuysButton",         "Enabled");
			common.setButtonState("orderListShowSellsButton",        "Selected");
		    }
		    if (novaUserOrders.doExpandOrderList) {
			common.setButtonState("orderListExpandAllButton",   "Selected");
			common.setButtonState("orderListCollapseAllButton", "Enabled");
		    } else {
			common.setButtonState("orderListExpandAllButton",   "Enabled");
			common.setButtonState("orderListCollapseAllButton", "Selected");
		    }
		}
		for (let i = 0; i < userOrders.length; ++i) {
		    const userOrder = userOrders[i];
		    const isBid = !!userOrder.userOfferBN ? false : true;
		    if (!(novaUserOrders.orderListType == 'all'            ) &&
			!(novaUserOrders.orderListType == 'buys'  &&  isBid) &&
			!(novaUserOrders.orderListType == 'sells' && !isBid) )
			continue;
		    const action = isBid ? "buy" : "sell";
		    const orderId = isBid ? userOrder.userBidBN.toString(10) : userOrder.userOfferBN.toString(10);
		    const filledSumBN = new BN("0");
		    const proceedsSumBN = new BN("0");
		    const offerDateDsp = dateDsp(userOrder.date);
		    const contractAmountDsp = BNToDsp(userOrder.totalBN, 3);
		    const filledBN = userOrder.totalBN.sub(userOrder.remainBN);
		    let remainBN = userOrder.remainBN;
		    let deleteAction = null;
		    let status = (userOrder.openEvents.length == 0) ? "closed" : "";

		    if (!novaUserOrders.doExpandOrderList) {
			// collapsed view - update fields for single line
			filledSumBN.iadd(filledBN);
			proceedsSumBN.iadd(userOrder.proceedsBN);
		    } else {
			// expanded view - show all details of initial offer
			let priceDsp = '';
			if (!filledBN.isZero())
			    priceDsp = BNToDsp(userOrder.proceedsBN.mul(ether.etherBN).div(filledBN), 2);
			else if (userOrder.openEvents.length >= 1)
			    priceDsp = BNToDsp(userOrder.openEvents[0].priceBN, 2);
			else
			    console.error("Either filled should be nz or there should be open events");
			const modifier = filledBN.isZero() ? "" : remainBN.isZero() ? "/fill" : "/part fill";
			orders.push({
			    id:     orderId,
			    date:   offerDateDsp,
			    type:   action + ' order' + modifier,
			    amount: contractAmountDsp,
			    price:  priceDsp,
			    filled: BNToDsp(filledBN, 3),
			    remain: BNToDsp(userOrder.remainBN, 3),
			    yields: !filledBN.isZero() ? BNToDsp(userOrder.proceedsBN, 2) + " DAI" : 'n/a',
			    typeClass: isBid ? "buyColor" : "sellColor",
			    deleteClass: "ordersListTableStatus",
			    deleteAction: null,
			    status: status,
			});
		    }
		    for (let j = 0; j < userOrder.openEvents.length; ++j) {
			const openEvent = userOrder.openEvents[j];
			const proceedsBN = openEvent.amountBN.mul(openEvent.priceBN).div(ether.etherBN);
			if (j == userOrder.openEvents.length - 1)
			    deleteAction = isBid ? () => onDelete(openEvent.openBidBN) : () => onDelete(openEvent.openOfferBN);
			status = "";
			if (j == userOrder.openEvents.length - 1 && openEvent.remainBN.isZero())
			    status = openEvent.amountBN.isZero() ? "cancelled" : "closed"
			if (!novaUserOrders.doExpandOrderList) {
			    // collapsed view - update fields for single line
			    filledSumBN.iadd(openEvent.amountBN);
			    proceedsSumBN.iadd(proceedsBN);
			} else {
			    // expanded view - show all details of each open event
			    // the "amount" in the event refers to the xfer amount. the "amount" that we display refers to the initial
			    // amount of the order outstanding before the transaction; "filled" is the xfer amount; and "remain" is the
			    // amount left after the xfer.
			    const openDateDsp = dateDsp(openEvent.date);
			    let type = "open " + action;
			    // could have used:
			    // startAmountBN = openEvent.amountBN.add(openEvent.remainBN);
			    // except that it doesn't account for cancellations :(
			    let startAmountBN = remainBN;
			    if (openEvent.amountBN.isZero() && openEvent.remainBN.isZero())
				type = "cancel";
			    else if (!openEvent.amountBN.isZero())
				type = openEvent.remainBN.isZero() ? "fill" : "partial fill";
			    const deleteable = (j == userOrder.openEvents.length - 1) && !openEvent.remainBN.isZero();
			    orders.push({
				id: '',
				date:   openDateDsp,
				type:   type,
				amount: BNToDsp(startAmountBN, 3),
				price:  BNToDsp(openEvent.priceBN, 2),
				filled: BNToDsp(openEvent.amountBN, 3),
				remain: BNToDsp(openEvent.remainBN, 3),
				yields: !openEvent.amountBN.isZero() ? BNToDsp(proceedsBN, 2) + " DAI": "n/a",
				typeClass: isBid ? "buyColor" : "sellColor",
				deleteClass: deleteable ? "ordersListTableDelete" : "ordersListTableStatus",
				deleteAction: deleteable ? deleteAction : null,
				status: status,
			    });
			}
			remainBN = openEvent.remainBN;
		    }
		    if (!novaUserOrders.doExpandOrderList) {
			let priceDsp = '';
			if (!filledSumBN.isZero())
			    priceDsp = BNToDsp(proceedsSumBN.mul(ether.etherBN).div(filledSumBN), 2);
			else if (userOrder.openEvents.length >= 1)
			    priceDsp = BNToDsp(userOrder.openEvents[0].priceBN, 2);
			else
			    console.error("Either filled should be nz or there should be open events (collapsed)");
			const deleteable = !remainBN.isZero();
			orders.push({
			    id:     orderId,
			    date:   offerDateDsp,
			    type:   action,
			    amount: contractAmountDsp,
			    price:  priceDsp,
			    filled: BNToDsp(filledSumBN, 3),
			    remain: BNToDsp(remainBN, 3),
			    yields: !proceedsSumBN.isZero() ? BNToDsp(proceedsSumBN, 2) + " DAI" : 'n/a',
			    typeClass: isBid ? "buyColor" : "sellColor",
			    deleteClass: deleteable ? "ordersListTableDelete" : "ordersListTableStatus",
			    deleteAction: deleteable ? deleteAction : null,
			    status: deleteable ? "" : status,
			});
		    }
		}
	    }
	}
    }
    return (
	    <div id="ordersListWidgetDiv" class="plank panel hidden">
            <div id="ordersListWaitIcon" className="hidden"/>
	      <div class="panelTitle">
		My Orders
	      </div>
	      <div class="panelSubtitle">
		{selectedDisplay}
	      </div>
	      <div id="orderListButtonsDiv">
		<button id="orderListShowBuysAndSellsButton" type="button" className="button" onClick={onShowBuysAndSells}>Show all</button>
		<button id="orderListShowBuysButton"         type="button" className="button" onClick={onShowBuys}>Show bids</button>
		<button id="orderListShowSellsButton"        type="button" className="button" onClick={onShowSells}>Show offers</button>
		<button id="orderListExpandAllButton"        type="button" className="button" onClick={onExpand}>Expand</button>
		<button id="orderListCollapseAllButton"      type="button" className="button" onClick={onCollapse}>Collapse</button>
	      </div>
	      <table class="ordersListTable">
		<tr>
		  <th id="ordersListTableIdHdr">order#</th>
		  <th id="ordersListTableDateHdr">date</th>
		  <th id="ordersListTableTypeHdr">type</th>
		  <th>amount</th>
		  <th>price</th>
		  <th>filled</th>
		  <th>remain</th>
	          <th>nominal proceeds/cost</th>
		</tr>
		{orders.map(order => (
			<tr>
			  <td >{order.id}</td>
			  <td>{order.date}</td>
			  <td className={order.typeClass}>{order.type}</td>
			  <td>{order.amount}</td>
			  <td>{order.price}</td>
			  <td>{order.filled}</td>
			  <td>{order.remain}</td>
			  <td>{order.yields}</td>
			  <td className={order.deleteClass} onClick={order.deleteAction}>{order.status}</td>
			</tr>
			))}
	      </table>
	   </div>
	  );
}

export default OrdersListWidget;
