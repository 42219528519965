import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// needed for async when using bable
const regeneratorRuntime = require("regenerator-runtime");
const common = require('../lib/common');
const ether = require('../lib/ether');
const novaEther = require('../lib/novaEther');
const to = require('../lib/to');
const BN = require("bn.js");


// utils
//
export const selectedDsp = (novaSecurity, novaContractSelector) => {
    let selectedDisplay = "Selected contract: No Option Contract Selected";
    if (!!novaSecurity.security && !novaSecurity.securityInfoIsPending) {
	const intervalBN = novaSecurity.security.expirationIntervalBN;
	const dateBN = novaSecurity.security.expirationBaseBN.clone();
	dateBN.iadd(intervalBN.muln(novaContractSelector.selectedExpirationIdx));
	const date = new Date();
	date.setTime(dateBN.muln(1000).toString(10));
	const dateDisplay = common.days[date.getUTCDay()] + ' ' + common.months[date.getUTCMonth()] + ' ' +
	      common.leftPadTo(date.getUTCDate().toString(), 2, '0')  + ' ' +
	      common.leftPadTo(date.getUTCHours().toString(), 2, '0') + ":" +
	      common.leftPadTo(date.getUTCMinutes().toString(), 2, '0')  + ":" +
	      common.leftPadTo(date.getUTCSeconds().toString(), 2, '0');
	const securityText = novaSecurity.wethSelected ? "WETH"  : "WBTC";
	//Call 100 WETH @ 200.00 exp: 20201104:230000 UTC
	const priceMaybeDecimals = ether.convertWeiToDecimal(novaContractSelector.selectedStrikePriceBN.toString(10), 2);
	const priceWith2Decimals = parseFloat(priceMaybeDecimals).toFixed(2);
	const tokensPerContract = parseInt(ether.convertWeiToDecimal(novaSecurity.security.tokensPerContractBN.toString(10), 3));
	selectedDisplay =
	    (novaSecurity.callSelected ? "Call" : "Put") + " " +
	    tokensPerContract + " " +
	    securityText + " " +
	    "@ " + priceWith2Decimals + " " +
	    "exp: " + dateDisplay;
    }
    return selectedDisplay;
};


// thunks
// note arg is the argument password to the created thunk fcn
// note thunkAPI = { dispatch, getState, extra, rejectWithValue }
// where extra is the "extra argument" given to the thunk middleware on setup, if available
//
export const effectExpirationIdx = createAsyncThunk(
    'novaContractSelector/effectExpirationIdx',
    async (expirationIdx, thunkAPI) => {
        console.log('at least im in the thunk');
	return new Promise(async (resolve, reject) => {
	    try {
		thunkAPI.dispatch(setSelectedExpirationIdx(expirationIdx));
		resolve('okey dokey');
	    } catch (err) {
		console.log('effectExpirationIdx err = ' + err);
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
)

export const thunkEffectStrikePriceIdx = createAsyncThunk(
    'novaContractSelector/thunkEffectStrikePriceIdx',
    async (strikePriceIdx, thunkAPI) => {
        console.log('at least im in the thunk');
	return new Promise(async (resolve, reject) => {
	    try {
		thunkAPI.dispatch(setSelectedStrikePriceIdx(strikePriceIdx));
		resolve('okey dokey');
	    } catch (err) {
		console.log('thunkEffectStrikePriceIdx err = ' + err);
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
)


let chainId = -1;
export const thunkGetStrikePrices = createAsyncThunk(
    'novaContractSelector/thunkGetStrikePrices',
    async (arg, thunkAPI) => {
        console.log('im in the thunkGetStrikePrices thunk');
	return new Promise(async (resolve, reject) => {
	    try {
		const securityId = arg.securityId;
		const expirationIdx = arg.expirationIdx;

		if (thunkAPI.getState().novaSecurity.pending) {
		    console.log('thunkGetStrikePrices: pending');
		}
		if (typeof window.ethereum === 'undefined') {
		    const err = 'MetaMask is not installed!';
		    console.log(err);
		    thunkAPI.rejectWithValue(err);
		    return;
		}
		console.log('MetaMask is installed!');
		if (!common.provider) {
		    // init ethereum provider, common.web3
		    ether.initProvider('metamask');
		}
		if (chainId < 0 || chainId != ether.chainId) {
		    const networkName = await ether.init();
		    console.log('network: ' + networkName + ', ether.chainId = ' + ether.chainId);
		    chainId = ether.chainId;
		    novaEther.init(ether.chainId);
		}
		if (!!novaEther.OPTIONCONTRACT_ADDR) {
		    novaEther.getStrikePrices(securityId, expirationIdx)
			.then(strikePriceInfo => {
			    thunkAPI.dispatch(setPrices(strikePriceInfo.strikePriceBNs));
			})
			.catch(err => {
			    console.log('thunkGetStrikePrices err = ' + err);
			    thunkAPI.rejectWithValue(err);
			});
		}
	    } catch (err) {
		console.log('thunkGetStrikePrices err = ' + err);
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
)


export const novaContractSelectorSlice = createSlice({
    name: 'novaContractSelector',
    initialState: {
	changeMarker: 0,
	selectedExpirationIdx: 0,
	selectedStrikePriceIdx: 0,
	selectedStrikePriceBN: null,
	strikePriceBNs: [ 0, 0, 0, 0, 0, 0 ],
    },
    reducers: {
	setSelectedExpirationIdx: (state, action) => {
	    state.selectedExpirationIdx = action.payload;
	    ++state.changeMarker;
	},
	setSelectedStrikePriceIdx: (state, action) => {
	    state.selectedStrikePriceIdx = action.payload;
	    state.selectedStrikePriceBN = state.strikePriceBNs[state.selectedStrikePriceIdx];
	    ++state.changeMarker;
	},
	setPrices: (state, action) => {
	    state.strikePriceBNs = action.payload;
	    state.selectedStrikePriceBN = state.strikePriceBNs[state.selectedStrikePriceIdx];
	},
    },
    extraReducers: {
	// Add reducers for additional action types here, and handle loading state as needed
	// thunks will have X.pending, X.fulfilled, X.rejected
    }
})

export const {
    setSelectedExpirationIdx,
    setSelectedStrikePriceIdx,
    setPrices,
} = novaContractSelectorSlice.actions;

export const selectNovaContractSelector = state => state.novaContractSelector;
export default novaContractSelectorSlice.reducer;
