import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import '../css/common.css';
import { selectWallet,
	 thunkConnectMetamask,
	 setConnected }             from "../features/walletSlice";

const common = require('../lib/common');
const ether = require('../lib/ether');

const ConnectWalletButton = () => {
    const wallet = useSelector(selectWallet);

    let toolTipText = '';
    let buttonText = 'Connect to Metamask';
    const beenRendered = !!document.getElementById('connectWalletButtonDiv');
    if (beenRendered) {
	if (!!wallet.connected) {
	    buttonText = wallet.displayAddress;
	    toolTipText = wallet.address;
	    common.replaceElemClassFromTo("fullAddrTooltip", "hidden", "visible");
	    common.setButtonState("connectWalletButton", "Selected");
	} else {
	    buttonText = "Connect to Metamask";
	    toolTipText = '';
	    common.replaceElemClassFromTo("fullAddrTooltip", "visible", "hidden");
	    common.setButtonState("connectWalletButton", "Enabled");
	}
    }

    const dispatch = useDispatch();
    const onSubmit = () => {
	if (!wallet.connected) {
	    dispatch(thunkConnectMetamask(1));
	}
    };

    return (
	    <div id="connectWalletButtonDiv" className="tooltip">
	    <button id="connectWalletButton" className="topAreaButton button" type="button" onClick={onSubmit}>{buttonText}</button>
	    <span id="fullAddrTooltip" className="tooltipText hidden">{toolTipText}</span>
	    </div>
	);
}


export default ConnectWalletButton;
