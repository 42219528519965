import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import '../css/common.css';
import { setMode, selectPage }      from "../features/pageSlice";
import { selectNovaSecurity }       from "../features/novaSecuritySlice";

const common = require('../lib/common');


const WriteContractButton = () => {
    const page = useSelector(selectPage);
    const novaSecurity = useSelector(selectNovaSecurity);

    const beenRendered = !!document.getElementById('writeContractButtonDiv');
    if (beenRendered) {
	if (page.pageMode == "write")
	    common.replaceElemClassFromTo("writeContractButtonDiv", "visible", "selected", false);
	else {
	    // technically we could enable the write-contract functionality before the security info is displayed;
	    // but i think it's cleaner to require the user to wait
	    common.replaceElemClassFromTo("writeContractButtonDiv", "selected", "visible", novaSecurity.securityInfoIsPending);
	}
    }

    const dispatch = useDispatch();
    const onSubmit = () => {
	dispatch(setMode('write'))
    };

    return (
            <button id="writeContractButtonDiv" type="button" className="plank panel pageModeButton visible"
		    onClick={onSubmit}>Write, Exercise, or Cancel selected contract</button>
    );
}

export default WriteContractButton;
