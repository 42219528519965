import React from 'react';
import '../css/common.css';

function ViewMyContractsButton(props) {
    return (
	    <div id="viewMyContractsButtonDiv">
	    <button className="leftSideButton" type="button">View all my contracts</button>
	    </div>
	);
}

export default ViewMyContractsButton;
