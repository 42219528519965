import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { thunkCheckBalances }            from "../features/walletSlice";
import { thunkGetContractMemo }          from "../features/novaContractMemoSlice";


// needed for async when using bable
const regeneratorRuntime = require("regenerator-runtime");
const common = require('../lib/common');
const ether = require('../lib/ether');
const novaEther = require('../lib/novaEther');
const to = require('../lib/to');
const BN = require("bn.js");

const MAKER_COMMISION_PCTX100 = 25;   // 0.25%
const TAKER_COMMISION_PCTX100 = 35;   // 0.35%

// --------------------------------------------------------------------------------------------------------------------------
// thunks
// --------------------------------------------------------------------------------------------------------------------------

export const thunkCalcOrderDepositAmount = createAsyncThunk(
    'novaPlaceOrder/thunkCalcOrderDepositAmount',
    async (arg, thunkAPI) => {
        console.log('im in the thunkCalcOrderDepositAmount thunk A');
	const state = thunkAPI.getState();
	return new Promise(async (resolve, reject) => {
	    try {
		const securityId       = state.novaSecurity.security.id;
		const orderIsBuy       = state.novaPlaceOrder.orderIsBuy;
		const contractAmountBN = !!state.novaPlaceOrder.orderContractAmountWeiBN ? state.novaPlaceOrder.orderContractAmountWeiBN : new BN(0);
		const orderPriceBN     = !!state.novaPlaceOrder.orderPriceWeiBN ? state.novaPlaceOrder.orderPriceWeiBN : new BN(0);
		const security         = await novaEther.getSecurity(securityId);
		if (!security)
		    throw('invalid security');
		console.log('thunkCalcOrderDepositAmount B');
		const daiNominalAmountWeiBN =  contractAmountBN.mul(orderPriceBN).div(ether.etherBN);
		const daiNominalAmount = ether.convertWeiToDecimal(daiNominalAmountWeiBN.toString(10), 2);
		const daiFeeAmountWeiBN = daiNominalAmountWeiBN.muln(TAKER_COMMISION_PCTX100).divn(10000);
		const daiFeeAmount = ether.convertWeiToDecimal(daiFeeAmountWeiBN.toString(10), 2);
		if (orderIsBuy) {
		    console.log('thunkCalcOrderDepositAmount B - 1');
		    // buy requires deposit of sufficent dai to complete purchase
		    thunkAPI.dispatch(setOrderDepositAmount({
			daiNominalAmount:      daiNominalAmount,
			daiNominalAmountWeiBN: daiNominalAmountWeiBN,
			daiFeeAmount:          daiFeeAmount,
			daiFeeAmountWeiBN:     daiFeeAmountWeiBN }));
		} else {
		    console.log('thunkCalcOrderDepositAmount B - 2');
		    // for sell nominal amount indicates nominal proceeds; we don't calc fees; deposit amount is contract amount
		    const contractReserveAmount = ether.convertWeiToDecimal(contractAmountBN.toString(10), 3);
		    thunkAPI.dispatch(setOrderDepositAmount({
			daiNominalAmount:      daiNominalAmount,
			daiNominalAmountWeiBN: daiNominalAmountWeiBN,
			daiFeeAmount:          daiFeeAmount,
			daiFeeAmountWeiBN:     daiFeeAmountWeiBN }));
		}
		resolve('thunkCalcOrderDepositAmount: okey dokey');
	    } catch (err) {
		console.error('thunkCalcOrderDepositAmount err = ' + err);
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
);

export const thunkPlaceOrder = createAsyncThunk(
    'novaPlaceOrder/thunkPlaceOrder',
    async (arg, thunkAPI) => {
        console.log('im in the thunkPlaceOrder thunk');
	const state = thunkAPI.getState();
	return new Promise(async (resolve, reject) => {
	    try {
		const securityId       = state.novaSecurity.security.id;
		const expirationIdx    = state.novaContractSelector.selectedExpirationIdx;
		const strikePriceIdx   = state.novaContractSelector.selectedStrikePriceIdx;
		const strikePriceBN    = state.novaContractSelector.selectedStrikePriceBN;
		const contractAmountBN = state.novaPlaceOrder.orderContractAmountWeiBN;
		const orderPriceBN     = state.novaPlaceOrder.orderPriceWeiBN;
		const contractMemoId = await novaEther.getContractMemoId(securityId, expirationIdx, strikePriceIdx);
		console.log('thunkPlaceOrder: contractMemoId = ' + contractMemoId);
		if (!!contractMemoId && !!parseInt(contractMemoId)) {
		    // verify strikeprice
		    console.log('thunkPlaceOrder: calling getContractMemo');
		    const contractMemo = await novaEther.getContractMemo(contractMemoId);
		    if (!strikePriceBN.eq(contractMemo.strikePriceBN))
			throw('Strike-Price has changed');
		    console.log('thunkPlaceOrder - existing: contractAmount = ' + contractAmountBN.toString(10));
		    if (state.novaPlaceOrder.orderIsBuy) {
			const sortHint = 0;
			console.log('thunkPlaceOrder: calling createBid');
			const txid = await novaEther.createBid(contractMemoId, contractAmountBN, orderPriceBN, sortHint);
		    } else {
			const sortHint = 0;
			const txid = await novaEther.createOffer(contractMemoId, contractAmountBN, orderPriceBN, sortHint);
		    }
		} else if (state.novaPlaceOrder.orderIsBuy) {
		    const txid = await novaEther.createBidNewMemo(securityId, expirationIdx, strikePriceIdx, strikePriceBN,
								  contractAmountBN, orderPriceBN);
		} else {
		    throw('Contract must exist before placing sell order');
		}
		thunkAPI.dispatch(thunkCheckBalances());
		thunkAPI.dispatch(thunkGetContractMemo({ securityId:     securityId,
							 expirationIdx:  expirationIdx,
							 strikePriceIdx: strikePriceIdx }));
		thunkAPI.dispatch(setOrderPrice(''));
		thunkAPI.dispatch(setOrderAmount({ contractAmount: '', securityAmount: '' }));
		thunkAPI.dispatch(thunkCalcOrderDepositAmount({ }));
		resolve('thunkPlaceOrder: okey dokey');
	    } catch (err) {
		console.error('thunkPlaceOrder err = ' + err);
		// for some reason rejectWithValue is ineffective when a metamask transaction is rejected. hence rejectHack.
		thunkAPI.dispatch(rejectHack());
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
)


function setPending(state, set) {
    console.log("setPending(state, " + set + ") cnt = " + state.userOrdersPendingCnt);
    if (set) {
	++state.placeOrderPendingCnt;
	state.placeOrderIsPending = true;
    } else {
	if (--state.placeOrderPendingCnt <= 0) {
	    state.writeContractPendingCnt = 0
	    state.placeOrderIsPending = false;
	}
    }
}

export const novaPlaceOrderSlice = createSlice({
    name: 'novaPlaceOrder',
    initialState: {
	orderIsBuy: true,
	orderPrice: 0.0,
	orderPriceWeiBN: null,
	orderContractAmount: 0.0,
	orderContractAmountWeiBN: null,
	orderSecurityAmount: 0.0,
	//
	orderDaiNominalAmount: 0.0,
	orderDaiNominalAmountWeiBN: null,
	orderDaiFeeAmount: 0.0,
	orderDaiFeeAmountWeiBN: null,
	placeOrderPendingCnt: 0,
	placeOrderIsPending: 0,
    },
    reducers: {
	setOrderIsBuy: (state, action) => {
	    state.orderIsBuy = action.payload;
	},
	setOrderPrice: (state, action) => {
	    state.orderPrice = action.payload;
	    state.orderPriceWeiBN = !!state.orderPrice ? ether.convertDecimalToWeiBN(state.orderPrice.toString()) : null;
	},
	setOrderAmount: (state, action) => {
	    state.orderContractAmount = action.payload.contractAmount;
	    state.orderSecurityAmount = action.payload.securityAmount;
	    state.orderContractAmountWeiBN = !!state.orderContractAmount ? ether.convertDecimalToWeiBN(state.orderContractAmount.toString()) : null;
	},
	setOrderDepositAmount: (state, action) => {
	    state.orderDaiNominalAmount      = action.payload.daiNominalAmount;
	    state.orderDaiNominalAmountWeiBN = action.payload.daiNominalAmountWeiBN;
	    state.orderDaiFeeAmount          = action.payload.daiFeeAmount;
	    state.orderDaiFeeAmountWeiBN     = action.payload.daiFeeAmountWeiBN;
	},
	rejectHack: (state, action) => {
	    // for some reason rejectWithValue is ineffective when a metamask transaction is rejected. hence rejectHack.
	    setPending(state, false);
	},
    },
    extraReducers: {
	// Add reducers for additional action types here, and handle loading state as needed
	// thunks will have X.pending, X.fulfilled, X.rejected
	[thunkPlaceOrder.pending]:   (state, action) => { setPending(state, true);  },
	[thunkPlaceOrder.fulfilled]: (state, action) => { setPending(state, false); },
	[thunkPlaceOrder.rejected]:  (state, action) => { setPending(state, false); },
    }
})

export const {
    setOrderIsBuy,
    setOrderPrice,
    setOrderAmount,
    setOrderDepositAmount,
    rejectHack,
} = novaPlaceOrderSlice.actions;

export const selectNovaPlaceOrder = state => state.novaPlaceOrder;
export default novaPlaceOrderSlice.reducer;
