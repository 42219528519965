import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { thunkCheckBalances }            from "../features/walletSlice";
import { thunkGetContractMemo }          from "../features/novaContractMemoSlice";

// needed for async when using bable
const regeneratorRuntime = require("regenerator-runtime");
const common = require('../lib/common');
const ether = require('../lib/ether');
const novaEther = require('../lib/novaEther');
const to = require('../lib/to');
const BN = require("bn.js");

// --------------------------------------------------------------------------------------------------------------------------
// thunks
// --------------------------------------------------------------------------------------------------------------------------

//
// get a list of my orders to state.userOrders[]
//
export const thunkGetMyOrders = createAsyncThunk(
    'novaUserOrders/thunkGetMyOrders',
    async (arg, thunkAPI) => {
        console.log('im in the thunkGetMyOrders thunk');
	const state = thunkAPI.getState();
	return new Promise(async (resolve, reject) => {
	    try {
		const contractMemoId = state.novaContractMemo.contractMemoId;
		console.log('contractMemoId = ' + contractMemoId);
		const userOrders = [];
		const offerLogs = await ether.getLogs({ fromBlock: 'earliest', toBlock: 'latest', address: novaEther.SORTEDMARKET_ADDR,
							topics: [ novaEther.getUserOfferEvTopic0(),
								  common.numberToHex256(ether.LOCAL_ETH_ADDR),
								  common.numberToHex256(contractMemoId),
								] });
		console.log('got logs: ' + JSON.stringify(offerLogs));
		for (let i = 0; i < offerLogs.length; ++i) {
		    const userOfferEvent = await novaEther.parseUserOfferEv(offerLogs[i]);
		    userOfferEvent.openEvents = [];
		    userOrders.push(userOfferEvent);
		}
		for (let i = 0; i < userOrders.length; ++i) {
		    const logs = await ether.getLogs({ fromBlock: 'earliest', toBlock: 'latest', address: novaEther.SORTEDMARKET_ADDR,
						       topics: [ novaEther.getOpenOfferEvTopic0(), common.BNToHex256(userOrders[i].userOfferBN) ] });
		    for (let j = 0; j < logs.length; ++j) {
			const openOfferEvent = await novaEther.parseOpenOfferEv(logs[j]);
			userOrders[i].openEvents.push(openOfferEvent);
		    }
		}
		//
		const firstBid = userOrders.length;
		const bidLogs = await ether.getLogs({ fromBlock: 'earliest', toBlock: 'latest', address: novaEther.SORTEDMARKET_ADDR,
						      topics: [ novaEther.getUserBidEvTopic0(),
								common.numberToHex256(ether.LOCAL_ETH_ADDR),
								common.numberToHex256(contractMemoId),
							      ] });
		console.log('got logs: ' + JSON.stringify(bidLogs));
		for (let i = 0; i < bidLogs.length; ++i) {
		    const userBidEvent = await novaEther.parseUserBidEv(bidLogs[i]);
		    userBidEvent.openEvents = [];
		    userOrders.push(userBidEvent);
		}
		for (let i = firstBid; i < userOrders.length; ++i) {
		    const logs = await ether.getLogs({ fromBlock: 'earliest', toBlock: 'latest', address: novaEther.SORTEDMARKET_ADDR,
						       topics: [ novaEther.getOpenBidEvTopic0(), common.BNToHex256(userOrders[i].userBidBN) ] });
		    for (let j = 0; j < logs.length; ++j) {
			const openBidEvent = await novaEther.parseOpenBidEv(logs[j]);
			userOrders[i].openEvents.push(openBidEvent);
		    }
		}
		userOrders.sort((a, b) => { return(a.date - b.date); });
		thunkAPI.dispatch(setUserOrders(userOrders));
		resolve('thunkGetMyOrders: okey dokey');
	    } catch (err) {
		console.error('thunkGetMyOrders err = ' + err);
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
)


//
// cancel an openOrder
//
export const thunkCancelOrder = createAsyncThunk(
    'novaUserOrders/thunkCancelOrder',
    async (arg, thunkAPI) => {
        console.log('im in the thunkCancelOrder thunk');
	const state = thunkAPI.getState();
	return new Promise(async (resolve, reject) => {
	    try {
		const securityId       = state.novaSecurity.security.id;
		const expirationIdx    = state.novaContractSelector.selectedExpirationIdx;
		const strikePriceIdx   = state.novaContractSelector.selectedStrikePriceIdx;
		const contractMemoId   = await novaEther.getContractMemoId(securityId, expirationIdx, strikePriceIdx);
		const orderIdBN        = arg;
		console.log('thunkCancelOrder: contractMemoId = ' + contractMemoId + ', id = ' + orderIdBN.toString(10));
		if (!!contractMemoId && !!parseInt(contractMemoId)) {
		    console.log('thunkCancelOrder: 1');
		    const txid = await novaEther.cancelOrder(contractMemoId, orderIdBN);
		    console.log('thunkCancelOrder: 2');
		} else {
		    console.log('thunkCancelOrder: 3');
		    throw('Contract must exist before canceling an order');
		}
		// process refunded deposits
		console.log('thunkCancelOrder: 4');
		thunkAPI.dispatch(thunkCheckBalances());
		// reserved amount may have changed
		console.log('thunkCancelOrder: 5');
		thunkAPI.dispatch(thunkGetContractMemo({ securityId:     securityId,
							 expirationIdx:  expirationIdx,
							 strikePriceIdx: strikePriceIdx }));
		console.log('thunkCancelOrder: 6');
		resolve('thunkCancelOrder: okey dokey');
	    } catch (err) {
		console.error('thunkCancelOrder err = ' + err);
		// for some reason rejectWithValue is ineffective when a metamask transaction is rejected. hence rejectHack.
		thunkAPI.dispatch(rejectHack());
		thunkAPI.rejectWithValue(err);
	    }
	});
    }
)


function setPending(state, set) {
    console.log("setPending(state, " + set + ") cnt = " + state.userOrdersPendingCnt);
    if (set) {
	++state.userOrdersPendingCnt;
	state.userOrdersIsPending = true;
    } else {
	if (--state.userOrdersPendingCnt <= 0) {
	    state.userOrdersPendingCnt = 0
	    state.userOrdersIsPending = false;
	}
    }
}

export const novaUserOrdersSlice = createSlice({
    name: 'novaUserOrders',
    initialState: {
	changeMarker: 0,
	userOrdersPendingCnt: 0,
	userOrdersIsPending: false,
	//
	doExpandOrderList: false,
	orderListType: 'all',
	userOrders: [],
    },
    reducers: {
	setUserOrders: (state, action) => {
	    state.userOrders = action.payload;
	},
	setExpandOrderList: (state, action) => {
	    state.doExpandOrderList = action.payload;
	},
	setOrderListType: (state, action) => {
	    state.orderListType = action.payload;
	},
	rejectHack: (state, action) => {
	    // for some reason rejectWithValue is ineffective when a metamask transaction is rejected. hence rejectHack.
	    setPending(state, false);
	},
    },
    extraReducers: {
	// Add reducers for additional action types here, and handle loading state as needed
	// thunks will have X.pending, X.fulfilled, X.rejected
	[thunkGetMyOrders.pending]:   (state, action) => { setPending(state, true);  },
	[thunkGetMyOrders.fulfilled]: (state, action) => { setPending(state, false); },
	[thunkGetMyOrders.rejected]:  (state, action) => { setPending(state, false); },
	[thunkCancelOrder.pending]:   (state, action) => { setPending(state, true);  },
	[thunkCancelOrder.fulfilled]: (state, action) => { setPending(state, false); },
	[thunkCancelOrder.rejected]:  (state, action) => { setPending(state, false); },
    }
})


export const {
    setContractMemo,
    setUserOrders,
    setExpandOrderList,
    setOrderListType,
    rejectHack,
} = novaUserOrdersSlice.actions;


export const selectNovaUserOrders = state => state.novaUserOrders;
export default novaUserOrdersSlice.reducer;
